const SubSquadSearchTips = (): JSX.Element => (
  <div className="box-border absolute flex w-3/4 p-4 my-1 text-base bg-white border border-gray-500 top-12 z-50">
    <div>
      <p>
        <span className="tracking-wider font-bold">Search Tips</span>
      </p>
      <ul className="flex flex-col gap-2 list-disc pl-4 py-4">
        <li className="tracking-wider font-light">
          Use <span className="font-bold">gurn:</span> to search by group urn
        </li>
      </ul>
    </div>
  </div>
)

export default SubSquadSearchTips
