import React, { PropsWithChildren } from 'react'
import ReactPaginate from 'react-paginate'
import Select from 'react-select'
import { Styles } from 'types/components'
import { OptionType } from './Dropdown'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

interface TableProps<T> {
  data: T[]
  columns: string[]
  showActions?: boolean
  pageCount: number
  currentPage: number
  onPageChange: (selectedItem: { selected: number }) => void
  paginate?: boolean
  perPage?: number
  onPerPageChange?: (item: OptionType | null) => void
  itemCount?: number
}

export const PAGE_SIZE_OPTIONS = [
  { label: '10', value: 10 },
  { label: '30', value: 30 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
]

function Table<T>({
  data,
  columns,
  showActions,
  pageCount,
  children,
  currentPage,
  onPageChange,
  paginate,
  perPage,
  onPerPageChange,
  itemCount,
}: PropsWithChildren<TableProps<T>>): React.ReactElement {
  const customPerPageStyle = {
    container: (styles: Styles) => ({
      ...styles,
      minWidth: '65px',
    }),
    control: (styles: Styles) => ({
      ...styles,
      boxShadow: 'none',
      borderColor: '#C4C4C4',
      ':hover': {
        borderColor: '#0746C0',
        borderWidth: '2px',
      },
      height: '25px',
      minHeight: '25px',
      borderRadius: '0.15rem',
    }),
    option: (
      styles: Styles,
      state: { isFocused: boolean; isSelected: boolean }
    ) => ({
      ...styles,
      color: state.isSelected || state.isFocused ? 'black' : 'black', // TODO: double check | changed to ||
      backgroundColor: state.isSelected
        ? '#D0D3DD'
        : state.isFocused
        ? '#E2DFDF'
        : 'white',
      ':hover': {
        backgroundColor: state.isFocused ? '#E2DFDF' : 'white',
      },
    }),
    dropdownIndicator: (styles: Styles) => ({
      ...styles,
      color: 'black',
      padding: '2px',
    }),
    valueContainer: (styles: Styles) => ({
      ...styles,
      height: '25px',
    }),
    indicatorContainer: (styles: Styles) => ({
      ...styles,
      padding: '0px',
    }),
  }

  return (
    <>
      <div id="TableContainer" className="overflow-x-auto" style={{transform: "rotateX(180deg)"}}>
      <div className="" style={{transform: "rotateX(180deg)"}}>
        <table className="whitespace-nowrap w-full">
          <thead className="">
            <tr className="text-black bg-primary bg-opacity-10">
              <>
                {columns.map((headerText: string) => (
                  <th
                    key={columns.indexOf(headerText)}
                    className="py-4 pl-4 font-bold tracking-wider text-left whitespace-normal"
                  >
                    {headerText}
                  </th>
                ))}
                {showActions && <th className="py-2 pl-6 text-left"></th>}
              </>
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
      </div>

      {/* Pagination Section */}
      {paginate && (
        <div className="flex flex-row justify-between w-full py-2">
          <div className="flex flex-row w-full">
            <p>
              <span className="tracking-wider font-light">
                {data?.length ? (
                  <>
                    {perPage === itemCount
                      ? `${currentPage * 10 - 9} - ${currentPage * 10}`
                      : `${currentPage * 10 - 9} - ${data?.length}`}
                  </>
                ) : (
                  '0'
                )}{' '}
                of {data?.length} items
              </span>
            </p>

            {/* Page Size Selection - TODO: need to test API pagination for functionality */}
            {perPage && onPerPageChange && (
              <div className="flex flex-row justify-between ml-2">
                <Select
                  options={PAGE_SIZE_OPTIONS}
                  defaultValue={PAGE_SIZE_OPTIONS[0]}
                  styles={customPerPageStyle}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onChange={onPerPageChange}
                  placeholder=""
                  isSearchable={false}
                />
                <span className="inline-block ml-2">records per page</span>
              </div>
            )}
          </div>

          <ReactPaginate
            nextClassName="ml-2"
            breakClassName="mx-2"
            previousClassName="mr-2"
            pageClassName="my-1 px-2"
            activeLinkClassName="text-white"
            activeClassName="my-1 px-2 bg-primary"
            breakLinkClassName="text-2xl text-gray-900"
            containerClassName="flex items-center self-end"
            nextLabel={<Icon icon={faChevronRight} />}
            breakLabel="..."
            previousLabel={<Icon icon={faChevronLeft} />}
            pageCount={pageCount}
            forcePage={currentPage - 1}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={onPageChange}
            initialPage={0}
          />
        </div>
      )}
    </>
  )
}

Table.defaultProps = {
  showActions: false,
  paginate: true,
}

export default Table
