import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Form, Formik, FormikProps } from 'formik'
import React, { PropsWithChildren } from 'react'
import Button from 'components/Button'
import Input from 'components/SearchInput'

type FormValues = {
  traderId: string
  merchantName: string
  merchantCode: string
}

type Props = {
  startFetching: (values: FormValues) => void
  startLoading: () => void
  closeSearchForm: () => void
}

function MerchantSearchForm({
  startFetching,
  startLoading,
  closeSearchForm,
}: PropsWithChildren<Props>): React.ReactElement {
  const initialValues: FormValues = {
    traderId: '',
    merchantName: '',
    merchantCode: '',
  }

  return (
    <div className="box-border absolute flex w-3/4 p-4 my-1 text-base bg-white border border-gray-500 pr-96 top-12 z-50">
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setStatus }) => {
          try {
            startLoading()
            closeSearchForm()
            // set fetching to true
            startFetching(values)
            // getFormikValues(values);
          } catch (error) {
            if (error instanceof Error) {
              setStatus(error.message)
            }
          }
        }}
      >
        {({ status, isValid }: FormikProps<FormValues>) => (
          <Form className="w-full mb-4" autoComplete="off">
            <Input
              name="traderId"
              type="text"
              label="Trader Id"
              labelClassName="text-gray-500"
            />
            <Input
              name="merchantName"
              type="text"
              label="Merchant Name"
              labelClassName="text-gray-500"
            />
            <Input
              name="merchantCode"
              type="text"
              label="Merchant Code"
              labelClassName="text-gray-500"
            />
            <Button
              type="submit"
              className="flex px-12 mt-5 text-sm"
              iconClassName="mt-0.5"
              icon={faSearch}
              disabled={!isValid}
              rounded
            >
              Search
            </Button>
            <p>{status}</p>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default MerchantSearchForm
