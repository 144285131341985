
/* eslint-disable @typescript-eslint/no-explicit-any */
import generatePDF, { Margin } from "react-to-pdf";

interface CSVOptions {
    filename?: string;
    delimiter?: string;
  }

const FILENAME_DATE = new Date().getTime()
const FILENAME_MODULE = "Transaction_Report"
const FILENAME = `${FILENAME_MODULE}-${FILENAME_DATE}`
  
export const ExportToCSV = <T extends Record<string, unknown>>(
    jsonData: T[],
    options: CSVOptions = {}
  ): void => {
    const { filename = `${FILENAME}.csv`, delimiter = ',' } = options;
    
    if (!jsonData?.length) return;
  
    const headers = Object.keys(jsonData[0]);
  
    const escapeCsvValue = (value: unknown): string => {
        const str = value === null || value === undefined ? '' : String(value);
        return /[",\n]/.test(str) ? `"${str.replace(/"/g, '""')}"` : str;
      };
    
      const getNestedValue = (obj: any, path: string): any => {
        return path.split('.').reduce((acc, key) => {
          if (acc && typeof acc === 'object' && key in acc) {
            return acc[key];
          }
          return '';
        }, obj);
      };
    
      // Generate CSV rows
      const csvRows = jsonData.map(row => 
        headers.map(field => 
          escapeCsvValue(getNestedValue(row, field))
        )
      );
    
      // Combine headers and rows
      const csvContent = [
        headers.join(delimiter),
        ...csvRows.map(row => row.join(delimiter))
      ].join('\n');
  
    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }


export const ExportToPDF = () => {
    generatePDF(() => document.getElementById("TableContainer"), {
      method: "save",
      filename: `${FILENAME}.pdf`,
      page: { margin: Margin.MEDIUM },
    });
  };