import { faChevronDown, faSearch, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import Button from 'components/Button'
import Dropdown, { OptionType } from 'components/Dropdown'
import { TRANSACTION_STATUS, TRANSACTION_TYPES } from 'constants/filters'
import { Form, Formik } from 'formik'
import { NavLink } from 'react-router-dom'

import React, { PropsWithChildren, useCallback, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useParams } from 'react-router-dom'
import { OptionTypeBase } from 'react-select'
import { formatDateToShort } from 'services/helpers'
import { DropDownItem } from 'types/components'

import useTransactionsFilter from './useTransactionsFilter'
import useTransactionsMerchantLookup from './useTransactionsMerchantLookup'

type DatePickerProps = React.InputHTMLAttributes<HTMLInputElement> & {
  value?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

function Transactions(): React.ReactElement {
  const [isOptions, isOptionsSet] = useState<boolean>(false)
  const { traderId } = useParams()
  const { merchantLookupDropdownOptions } = useTransactionsMerchantLookup({
    memberTraderId: String(traderId),
  })
  const {
    handleTransactionFilter,
    handleTransactionFilterClear,
    setFilterStartDate,
    setFilterEndDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedMerchant,
    setSelectedMerchant,
    initialValues,
    isFilteredTransactions,
    transactionsLoading,
  } = useTransactionsFilter({ memberTraderId: String(traderId) })

  //  start: Datepicker and formatting
  const onChangeDatepicker = (dates: [Date, Date] | null): void => {
    let start
    let end
    if (dates === null) {
      start = null
      end = null
      setFilterStartDate('')
      setFilterEndDate('')
    } else {
      start = dates[0]
      end = dates[1]
      setFilterEndDate('')
    }
    console.log(start, end, ' TEST ...')
    setStartDate(start)
    setEndDate(end)
  }

  const formatDate = (): string => {
    let dateString = ''
    if (startDate) {
      dateString = startDate.toLocaleDateString()
    }
    if (endDate) {
      dateString = dateString + ' - ' + endDate.toLocaleDateString()
    }
    return dateString || 'All'
  }

  const DatePickerInput = ({
    value,
    onClick,
  }: PropsWithChildren<DatePickerProps>): JSX.Element => (
    <button
      className="p-2 pr-10 text-left border border-gray-400"
      style={{ minWidth: '120px' }}
      onClick={onClick}
      type="button"
    >
      {formatDate()}
      {!value && (
        <Icon
          icon={faChevronDown}
          className="absolute top-0 right-0 h-full ml-4 mr-2"
        />
      )}
    </button>
  )

  // start: Merchant lookup dropdown options handler
  const merchantLoadOptions = useCallback(
    async (inputValue: string): Promise<DropDownItem[]> => {
      return merchantLookupDropdownOptions(inputValue)
    },
    [merchantLookupDropdownOptions]
  )

  return (
    <Formik initialValues={initialValues} onSubmit={handleTransactionFilter}>
      {({ values, setFieldValue }) => (
        <Form>
          <div className="flex flex-wrap items-end mt-4 mb-5 gap-2">
            <Dropdown
              title="Merchants"
              value={values.merchant}
              loadOptions={merchantLoadOptions}
              onChange={(option: OptionType) => {
                if (option) {
                  setSelectedMerchant(option)
                  setFieldValue('merchant', option.value)
                } else {
                  setSelectedMerchant(null)
                  setFieldValue('merchant', '')
                }
              }}
              // onInputChange={handleMerchantInputChange}
              className="w-60"
              borderColor="#C4C4C4"
              containerClassName="focus:border-primary"
              placeholder="Search merchant ..."
              isClearable
              isSearchable
              async
            />
            <div className="flex flex-col gap-2 ">
              <p>
                <span className="tracking-wider font-light">Date Range</span>
              </p>
              <DatePicker
                title="Date Picker"
                name="dateRange"
                onChange={onChangeDatepicker}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                className="p-2 border border-gray-400"
                placeholderText="All"
                customInput={<DatePickerInput />}
                selectsRange
                isClearable
                showYearDropdown
                scrollableYearDropdown      
              />
            </div>
            <Dropdown
              title="Transaction Type"
              value={values.transactionType}
              options={TRANSACTION_TYPES}
              onSelect={(option: DropDownItem) => {
                setFieldValue('transactionType', option)
              }}
              className="w-40"
              borderColor="#C4C4C4"
            />
            <Button
              type="submit"
              icon={faSearch}
              className="ml-1 rounded-lg"
              iconRight={false}
              disabled={transactionsLoading ? true : false}
            >
              <span className="tracking-wider font-bold">Filter</span>
            </Button>

            <Button
            type="button"
            icon={faDownload}
            className="ml-1 rounded-lg"
            iconRight={false}
            >        
              <NavLink to={`/reports/${traderId}/member-transactions-reports`} >
                <span className="tracking-wider font-bold">Download</span>
              </NavLink>
            </Button>

            {isFilteredTransactions ? (
              <Button
                type="reset"
                className="ml-2 rounded-lg"
                iconRight={false}
                onClick={handleTransactionFilterClear}
                disabled={transactionsLoading ? true : false}
              >
                <span className="tracking-wider font-bold">Clear</span>
              </Button>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Transactions
