import { addMembership } from 'api/memberships'
import Colors from 'colors.json'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import Input from 'components/Input'
import Modal from 'components/Modal'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { APIResponse } from 'types/app'
import { useQueryClient } from 'react-query'
import DatetimePickerInput from 'components/DatetimePickerInput'

import { useTraderGroups } from '../../Tabs/Memberships/useTraderGroups'

type FormValues = {
  membershipGroupId: number
  startDate: string
  endDate: string
}

interface Props {
  open: boolean
  onClose: () => void
}

const ModalAddMembership = ({ open, onClose }: Props): JSX.Element => {
  const { traderId } = useParams()
  const queryClient = useQueryClient()

  const { TraderGroupsOptions, TraderGroupsModStatus } = useTraderGroups()
  const initialValues: FormValues = {
    membershipGroupId: 0,
    startDate: '',
    endDate: '',
  }

  if (!traderId) {
    return <>Failed to load data</>
  }

  return (
    <Modal open={open} title="Add Membership" onClose={onClose} noFooter>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          try {
            console.log("Selected group 1", values)
            await addMembership(parseInt(traderId), {
              membershipGroupId: values.membershipGroupId,
              startDate: values.startDate,
              endDate: values.endDate,
            })
            onClose()
            toast.success('Membership added!')
            queryClient.invalidateQueries({ queryKey: ['useMemberships'] })
            actions.setSubmitting(false)
          } catch (error) {
            if (error instanceof Error) {
              toast.error(error.message)
              return
            }

            toast.error((error as APIResponse).outcomeUserMessage)
          }
        }}
      >
        {(formikProps) => {
          const { values, isSubmitting, setFieldValue } = formikProps
          const defaultDateValueStart = new Date(
            new Date().setHours(0, 0, 0)
          ).toString()
          const defaultDateValueEnd = new Date(
            new Date().setHours(23, 59, 59)
          ).toString()
          return (
            <Form className="flex flex-col">
              <div className="mb-4">
                {/* <Input
                  name="membershipGroupId"
                  label="Group ID"
                  required
                  containerClassName="w-full"
                /> */}
                <Dropdown
                  title="Groups"
                  options={TraderGroupsOptions}
                  className="w-full tracking-wider mb-4"
                  borderColor="#C4C4C4"
                  onSelect={(data) => {
                    console.log('Selected group:', data)
                    setFieldValue('membershipGroupId', String(data.value))
                  }}
                />
                <DatetimePickerInput
                  title={'Start Date'}
                  setFieldName="startDate"
                  setFieldValue={setFieldValue}
                  showTimeSelect={false}
                  defaultDateValue={defaultDateValueStart}
                />
                <DatetimePickerInput
                  title={'End Date'}
                  setFieldName="endDate"
                  setFieldValue={setFieldValue}
                  showTimeSelect={false}
                  defaultDateValue={defaultDateValueEnd}
                />
              </div>

              <footer className="flex justify-end pb-4 pl-4 pr-4">
                <Button
                  type="submit"
                  className="mr-2 rounded bg-primary"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
                <Button
                  className="bg-white border rounded border-primary"
                  onClick={onClose}
                  style={{ color: Colors.primary }}
                >
                  Cancel
                </Button>
              </footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ModalAddMembership
