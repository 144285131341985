import { useCallback, useState } from 'react'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import TSTable from 'components/Tanstack'
import { useMerchantDevicesStore } from 'store/merchantDevices'
import { useTableUtils } from 'hooks/useTableUtils'
import {
  MerchantDevicesDataTypes,
  MerchantDevicesModalActionsTypes,
} from 'types/merchantDevices'

export const useMerchantDevicesTable = () => {
  const merchantDevices = useMerchantDevicesStore(
    (state) => state.merchantDevices
  )

  const {
    selectedModal,
    selectedModalSet,
    selectedRowData,
    selectedRowDataSet,
  } = useTableUtils<
    MerchantDevicesDataTypes,
    MerchantDevicesModalActionsTypes
  >()

  const handleActionModalClose = useCallback(() => {
    selectedRowDataSet(null)
    selectedModalSet(null)
  }, [selectedModalSet, selectedRowDataSet])

  const columnHelper = createColumnHelper<MerchantDevicesDataTypes>()
  const columns = [
    columnHelper.accessor('deviceId', {
      header: () => 'Device ID',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('deviceCode', {
      header: () => 'Device Code',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('startDate', {
      header: () => 'Start Date',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('endDate', {
      header: () => 'End Date',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('deviceTypeName', {
      header: () => 'Device Type Name',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('comments', {
      header: () => 'Comments',
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => '',
      // cell: (info) => info.getValue(),
      cell: ({ row }) => (
        <div className="flex gap-2 px-4">
          <TSTable.TableCellButton
            onClick={() => {
              selectedRowDataSet(row.original)
              selectedModalSet('merchant_devices_preview')
            }}
            label="Preview"
            icon={faPencilAlt}
          />
        </div>
      ),
    }),
  ] as ColumnDef<MerchantDevicesDataTypes>[]

  return {
    merchantDevicesSelected: selectedRowData,
    merchantDevicesActionModal: selectedModal,
    merchantDevicesTableData: merchantDevices,
    merchantDevicesTableColumns: columns,
    handleMemberActionModalClose: handleActionModalClose,
  }
}
