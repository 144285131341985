import React, { useMemo, useState } from 'react'
import {
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import {
  ColumnDef,
  PaginationState,
  // createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { TableCellButton } from './TableCellButton'

interface TablePropsTypes<Tdata> {
  data: Tdata[]
  columns: ColumnDef<Tdata, string | number | unknown>[]
  pageSize?: number
}

export const Table = <Tdata,>({
  data,
  columns,
  pageSize = 10,
}: TablePropsTypes<Tdata>): React.ReactElement => {
  // const [{ pageIndex, pageSize }, paginationSet] = useState<PaginationState>({
  //   pageIndex: 0,
  //   pageSize: 10,
  // })

  // const pagination = useMemo(
  //   () => ({
  //     pageIndex,
  //     pageSize,
  //   }),
  //   [pageIndex, pageSize]
  // )

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageSize,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  return (
    <>
    <div className="overflow-x-auto" style={{transform: "rotateX(180deg)"}}>
      <div className="" style={{transform: "rotateX(180deg)"}}>
      <table className="whitespace-nowrap w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className="tracking-wider text-left pl-4 py-3  bg-opacity-20"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="py-2 text-black bg-white border-b-2 cursor-pointer hover:bg-primary hover:bg-opacity-5 "
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="py-4 pl-4 overflow-ellipsis whitespace-nowrap tracking-wider "
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      </div>
      </div>

      {data.length > table.getState().pagination.pageSize ? (
        <div className="flex items-center gap-2 py-2 mt-4 mb-4">
          {/* GO TO FIRST PAGE */}
          <TableCellButton
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            label="To first page"
            icon={faAngleDoubleLeft}
          />

          {/* GO TO PREVIOUS PAGE */}
          <TableCellButton
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            label="To previous page"
            icon={faAngleLeft}
          />

          {/* GO TO NEXT PAGE */}
          <TableCellButton
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            label="To previous page"
            icon={faAngleRight}
          />

          {/* GO TO LAST PAGE */}
          <TableCellButton
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
            label="To last page"
            icon={faAngleDoubleRight}
          />

          {/* PAGE CURRENT STATE */}
          <div className="ml-4 py-0.5 px-2 bg-primary bg-opacity-10 rounded-full">
            <span className="flex items-center gap-1 tracking-wider font-light text-xs text-primary text-opacity-60">
              <span>{table.getState().pagination.pageIndex + 1}</span>
              <span>of</span>
              <span>{table.getPageCount()}</span>
            </span>
          </div>
          {/* <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span> */}
          {/* <select
          value={table.getState().pagination.pageSize}
          onBlur={(e) => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select> */}
        </div>
      ) : null}

      {/* <div>{table.getRowModel().rows.length} Rows</div> */}
    
    </>
  )
}
