import { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient, useQuery } from 'react-query'

import { updateBankAccountStatusAPI } from 'api/financials'
import { useBankAccountsStore } from 'store/financials'

import { BankAccountsDataTypes } from 'types/financials'
import { FinancialUpdateBankAccountTxTypes } from 'types/financialUpdateBankAccountTxTypes'

import { DATASTATUS } from 'constants/status'

export const useMemberBankAccountsStatusUpdate = ({
  modalClose,
  selectedRowData,
}: {
  modalClose: () => void
  selectedRowData: BankAccountsDataTypes
}) => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const bankAccountsStatusUpdateModStatusSet = useBankAccountsStore(
    (state) => state.bankaccountsModStatusSet
  )

  const [bankAccountsStatusUpdateParams, bankAccountsStatusUpdateParamsSet] =
    useState<FinancialUpdateBankAccountTxTypes>({
      traderId: memberTraderId,
      accountStatusId: selectedRowData.accountStatusId.toString(),
      note: selectedRowData.note || '',
      bankAccountId: selectedRowData.bankAccountId,
      bankAccountName: selectedRowData.bankAccountName || '',
    })

  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation({
    mutationFn: updateBankAccountStatusAPI,
    mutationKey: 'updateBankAccountsStatusUpdate',
    onMutate: (values) => {
      bankAccountsStatusUpdateModStatusSet('loading')
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ['useBankAccounts'],
      })
      bankAccountsStatusUpdateModStatusSet('success')
    },
    onError: (error) => {
      bankAccountsStatusUpdateModStatusSet('fail')
    },
  })

  const handleStatusUpdate = useCallback(
    async (updateParams: FinancialUpdateBankAccountTxTypes) => {
      try {
        const response = await mutateAsync({
          ...bankAccountsStatusUpdateParams,
          ...updateParams,
        })
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        toast.success('Bank Account status update successful.')
      } catch (error) {
        toast.error(`Bank Account status update error.`)
        // modalClose()
      }
      modalClose()
    },
    [mutateAsync]
  )

  return {
    memberBankAccountsStatusUpdateParams: bankAccountsStatusUpdateParams,
    memberHandleBankAccountsStatusUpdate: handleStatusUpdate,
  }
}
