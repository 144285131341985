import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import { donationReversalAPI } from 'api/icash'
import { MerchantLookupTypes } from 'types/merchanTxTypes'
import {
  IcashDonationRefundTxFormTypes,
  IcashDonationRefundTxFormSubmitValuesTypes,
  IcashDonationRefundTxFormSubmitActionsTypes,
} from 'types/icashDonationRefundTxTypes'
import { MemberDetail } from 'types/member'
import useMemberStore from 'store/member'

const DONATIONREFUNDTXINITVAL: IcashDonationRefundTxFormTypes = {
  externalTransactionId: '',
  ident: '',
  creditValue: 0,
  fromTraderId: 0,
  // comments: '',
  // traceNumber: 0,
  // reasonId: 0,
}

export const useIcashDonationRefundTx = (
  traderId: number,
  onCloseModal: () => void
): {
  member: MemberDetail | undefined
  isLoading: boolean
  initialValues: IcashDonationRefundTxFormTypes
  isMerchantLookupToggle: boolean
  handleIsMerchantLookupToggle: () => void
  handleSubmitIcashDonationRefundTx: (
    values: IcashDonationRefundTxFormSubmitValuesTypes,
    actions: IcashDonationRefundTxFormSubmitActionsTypes
  ) => void
  handleClearIcashDonationRefundTx: () => void
} => {
  // AUTH TOKEN START
  const authToken = ls.get(LSKEY_TOKEN)
  const decodeAuthToken: { username: string } = jwt_decode(String(authToken))
  const authTokenUsername = decodeAuthToken?.username || ''
  // AUTH TOKEN END
  // STATES
  const [isMerchantLookupToggle, isMerchantLookupToggleSet] =
    useState<boolean>(false)
  const [isLoading, isLoadingSet] = useState<boolean>(false)
  const [purchaseTxMerchant, purchaseTxMerchantSet] = useState<
    MerchantLookupTypes[]
  >([])
  const member = useMemberStore((state) => state.member)
  console.log("test11", member)
  const [initialValues, initialValuesSet] =
    useState<IcashDonationRefundTxFormTypes>({
      ...DONATIONREFUNDTXINITVAL,
      externalTransactionId: '',
      // ident: String(member?.cardNumber) || '',
      fromTraderId: member?.traderId || 0,
    })

  const handleIsMerchantLookupToggle = useCallback(() => {
    isMerchantLookupToggleSet(!isMerchantLookupToggle)
  }, [isMerchantLookupToggle, isMerchantLookupToggleSet])

  const handleSubmitIcashDonationRefundTx = useCallback(
    async (
      values: IcashDonationRefundTxFormSubmitValuesTypes,
      actions: IcashDonationRefundTxFormSubmitActionsTypes
    ) => {
      isLoadingSet(true)
      try {
        await donationReversalAPI(values)
        toast.success('iCash donation refund successful.')
        onCloseModal()
        // actions.setSubmitting(false)
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        toast.error(message.outcomeUserMessage)
      }
      isLoadingSet(false)
    },
    [isLoadingSet]
  )

  const handleClearIcashDonationRefundTx = useCallback(() => {
    initialValuesSet({ ...DONATIONREFUNDTXINITVAL })
    toast.success('Filter successfully cleared.')
  }, [initialValuesSet])

  return {
    member,
    isLoading,
    initialValues,
    isMerchantLookupToggle,
    handleIsMerchantLookupToggle,
    handleSubmitIcashDonationRefundTx,
    handleClearIcashDonationRefundTx,
  }
}
