import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { getDeviceTypesAPI } from 'api/merchantdevices'
import { useDeviceTypesStore } from 'store/merchantDevices'
import { DropDownItem } from 'types/components'

import { DATASTATUS } from 'constants/status'

export const useMerchantDeviceTypes = () => {
  const { traderId } = useParams()
  const memberTraderId = parseInt(String(traderId) || '0')

  const DeviceTypesModStatus = useDeviceTypesStore(
    (state) => state.DeviceTypesModStatus
  )
  const DeviceTypesModStatusSet = useDeviceTypesStore(
    (state) => state.DeviceTypesModStatusSet
  )
  const DeviceTypesModErrors = useDeviceTypesStore(
    (state) => state.DeviceTypesModErrors
  )
  const DeviceTypesModErrorsSet = useDeviceTypesStore(
    (state) => state.DeviceTypesModErrorsSet
  )
  const DeviceTypes = useDeviceTypesStore((state) => state.DeviceTypes)
  const DeviceTypesSet = useDeviceTypesStore(
    (state) => state.DeviceTypesSet
  )

  const {
    data: dataComms,
    isLoading: isLoadingComms,
    isError: isErrorComms,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useDeviceTypes'],
    queryFn: async () => {
      try {
        DeviceTypesModStatusSet('loading')
        const response = await getDeviceTypesAPI()
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        DeviceTypesModStatusSet('success')
        return response.data
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)), ' ERR')
        DeviceTypesModStatusSet('fail')
        DeviceTypesModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataComms) DeviceTypesSet(dataComms)
  }, [dataComms, DeviceTypesSet])

  const DeviceTypesOptions: DropDownItem[] = useMemo(() => {
    return DeviceTypes.map((item) => ({
      label: String(item.name),
      value: String(item.deviceTypeId),
    }))
  }, [DeviceTypes])

  return {
    merchantDeviceTypesOptions: DeviceTypesOptions,
    memberDeviceTypes: DeviceTypes,
    merchantDeviceTypesModStatus: DeviceTypesModStatus,
  }
}
