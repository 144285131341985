import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { getTraderGroupsAPI } from 'api/memberships'
import { useTraderGroupsStore } from 'store/memberships'
import { DropDownItem } from 'types/components'

import { DATASTATUS } from 'constants/status'

export const useTraderGroups = () => {
  const TraderGroupsModStatus = useTraderGroupsStore(
    (state) => state.TraderGroupsModStatus
  )
  const TraderGroupsModStatusSet = useTraderGroupsStore(
    (state) => state.TraderGroupsModStatusSet
  )
  const TraderGroupsModErrors = useTraderGroupsStore(
    (state) => state.TraderGroupsModErrors
  )
  const TraderGroupsModErrorsSet = useTraderGroupsStore(
    (state) => state.TraderGroupsModErrorsSet
  )
  const TraderGroups = useTraderGroupsStore((state) => state.TraderGroups)
  const TraderGroupsSet = useTraderGroupsStore((state) => state.TraderGroupsSet)

  const {
    data: dataComms,
    isLoading: isLoadingComms,
    isError: isErrorComms,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useTraderGroups'],
    queryFn: async () => {
      try {
        TraderGroupsModStatusSet('loading')
        const response = await getTraderGroupsAPI()
        if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
        TraderGroupsModStatusSet('success')
        return response.data
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)), ' ERR')
        TraderGroupsModStatusSet('fail')
        TraderGroupsModErrorsSet(JSON.stringify(e))
      }
    },
  })

  useEffect(() => {
    if (dataComms) TraderGroupsSet(dataComms)
  }, [dataComms, TraderGroupsSet])

  const TraderGroupsOptions: DropDownItem[] = useMemo(() => {
    return TraderGroups.map((item) => ({
      label: String(item.groupName),
      value: String(item.groupId),
    }))
  }, [TraderGroups])

  return {
    TraderGroupsOptions: TraderGroupsOptions,
    TraderGroups: TraderGroups,
    TraderGroupsModStatus: TraderGroupsModStatus,
  }
}
