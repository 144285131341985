import { Auth } from '@aws-amplify/auth'
import { login as apiLogin } from 'api/auth'
import { LSKEY_TOKEN } from 'constants/app'
import { User } from 'types/users'
// import { User } from 'types/users'
import { ls } from 'utils'
import create from 'zustand'

import { MemberSearchStateStore } from "store/member-search-state"

type UserStore = {
  isLoggedIn: boolean
  isLoading: boolean
  user: User | undefined
  signIn: (username: string, token: string) => Promise<User>
  signOut: () => void
}

const useAuthStore = create<UserStore>((set) => ({
  isLoggedIn: !!ls.get(LSKEY_TOKEN),
  isLoading: !!ls.get(LSKEY_TOKEN),
  user: undefined,
  signIn: async (username: string, token: string) => {
    try {
      ls.add(LSKEY_TOKEN, token)
      const response = await apiLogin()
      set({
        isLoggedIn: true,
        isLoading: false,
        user: response,
      })
      // set user session
      // actions.setUser({ user: response })
      // actions.setTokens({ accessToken: response.token, cognitoToken })

      return Promise.resolve(response)
    } catch (error) {
      // console.log(error)
      Auth.signOut()
      return Promise.reject(error)
    }
  },
  signOut: (): void => {
    ls.remove(LSKEY_TOKEN)
    set({ isLoggedIn: false, user: undefined })
    MemberSearchStateStore.getState().searchArrayStringSet(null)
  },
}))

export default useAuthStore
