import React from 'react'

import Form from './Form'

const Index: React.FC<{ onClose: () => void; open: boolean }> = ({
  onClose,
  open,
}): React.ReactElement => {
  return <Form onClose={onClose} open={open} />
}

export default Index
