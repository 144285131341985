import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import {
  MerchantDevicesDataTypes,
  MerchantDevicesStatusesDataTypes,
  MerchantDeviceTypesDataTypes,
} from 'types/merchantDevices'

interface MerchantDeviceStoreTypes {
  merchantDevicesModErrors: StatusErrorTypes
  merchantDevicesModErrorsSet: (
    merchantDevicesModErrors: StatusErrorTypes
  ) => void
  merchantDevicesModStatus: StatusTypes
  merchantDevicesModStatusSet: (merchantDevicesModStatus: StatusTypes) => void
  merchantDevices: MerchantDevicesDataTypes[]
  merchantDevicesSet: (merchantDevices: MerchantDevicesDataTypes[]) => void
  merchantDevicesLookup: MerchantDevicesDataTypes[]
  merchantDevicesLookupSet: (
    merchantDevices: MerchantDevicesDataTypes[]
  ) => void
}

export const useMerchantDevicesStore = create<MerchantDeviceStoreTypes>(
  (set) => ({
    merchantDevicesModErrors: '',
    merchantDevicesModErrorsSet: (merchantDevicesModErrors) => {
      set({
        merchantDevicesModErrors,
      })
    },
    merchantDevicesModStatus: 'idle',
    merchantDevicesModStatusSet: (merchantDevicesModStatus) => {
      set({
        merchantDevicesModStatus,
      })
    },
    merchantDevices: [],
    merchantDevicesSet: (merchantDevices) => {
      set({
        merchantDevices,
      })
    },
    merchantDevicesLookup: [],
    merchantDevicesLookupSet: (merchantDevicesLookup) => {
      set({
        merchantDevicesLookup,
      })
    },
  })
)

interface MerchantDevicesLookupStoreTypes {
  merchantDevicesLookupModErrors: StatusErrorTypes
  merchantDevicesLookupModErrorsSet: (
    merchantDevicesLookupModErrors: StatusErrorTypes
  ) => void
  merchantDevicesLookupModStatus: StatusTypes
  merchantDevicesLookupModStatusSet: (
    merchantDevicesLookupModStatus: StatusTypes
  ) => void
  merchantDevicesLookup: MerchantDevicesDataTypes[]
  merchantDevicesLookupSet: (
    merchantDevicesLookup: MerchantDevicesDataTypes[]
  ) => void
}

export const useMerchantDevicesLookupStore =
  create<MerchantDevicesLookupStoreTypes>((set) => ({
    merchantDevicesLookupModErrors: '',
    merchantDevicesLookupModErrorsSet: (merchantDevicesLookupModErrors) => {
      set({
        merchantDevicesLookupModErrors,
      })
    },
    merchantDevicesLookupModStatus: 'idle',
    merchantDevicesLookupModStatusSet: (merchantDevicesLookupModStatus) => {
      set({
        merchantDevicesLookupModStatus,
      })
    },
    merchantDevicesLookup: [],
    merchantDevicesLookupSet: (merchantDevicesLookup) => {
      set({
        merchantDevicesLookup,
      })
    },
  }))

interface MerchantDevicesStatusesStoreTypes {
  merchantDevicesStatusesModErrors: StatusErrorTypes
  merchantDevicesStatusesModErrorsSet: (
    merchantDevicesStatusesModErrors: StatusErrorTypes
  ) => void
  merchantDevicesStatusesModStatus: StatusTypes
  merchantDevicesStatusesModStatusSet: (
    merchantDevicesStatusesStatus: StatusTypes
  ) => void
  merchantDevicesStatuses: MerchantDevicesStatusesDataTypes[]
  merchantDevicesStatusesSet: (
    merchantDevicesStatuses: MerchantDevicesStatusesDataTypes[]
  ) => void
  merchantDevicesStatusesLookup: MerchantDevicesStatusesDataTypes[]
  merchantDevicesStatusesLookupSet: (
    merchantDevicesStatuses: MerchantDevicesStatusesDataTypes[]
  ) => void
}

export const useMerchantDevicesStatusesStore =
  create<MerchantDevicesStatusesStoreTypes>((set) => ({
    merchantDevicesStatusesModErrors: '',
    merchantDevicesStatusesModErrorsSet: (merchantDevicesStatusesModErrors) => {
      set({
        merchantDevicesStatusesModErrors,
      })
    },
    merchantDevicesStatusesModStatus: 'idle',
    merchantDevicesStatusesModStatusSet: (merchantDevicesStatusesModStatus) => {
      set({
        merchantDevicesStatusesModStatus,
      })
    },
    merchantDevicesStatuses: [],
    merchantDevicesStatusesSet: (merchantDevicesStatuses) => {
      set({
        merchantDevicesStatuses,
      })
    },
    merchantDevicesStatusesLookup: [],
    merchantDevicesStatusesLookupSet: (merchantDevicesStatusesLookup) => {
      set({
        merchantDevicesStatusesLookup,
      })
    },
  }))

interface DeviceTypesStoreTypes {
  DeviceTypesModErrors: StatusErrorTypes
  DeviceTypesModErrorsSet: (DeviceTypesModErrors: StatusErrorTypes) => void
  DeviceTypesModStatus: StatusTypes
  DeviceTypesModStatusSet: (DeviceTypesStatus: StatusTypes) => void
  DeviceTypes: MerchantDeviceTypesDataTypes[]
  DeviceTypesSet: (DeviceTypes: MerchantDeviceTypesDataTypes[]) => void
  DeviceTypesLookup: MerchantDeviceTypesDataTypes[]
  DeviceTypesLookupSet: (DeviceTypes: MerchantDeviceTypesDataTypes[]) => void
}

export const useDeviceTypesStore = create<DeviceTypesStoreTypes>((set) => ({
  DeviceTypesModErrors: '',
  DeviceTypesModErrorsSet: (DeviceTypesModErrors) => {
    set({
      DeviceTypesModErrors,
    })
  },
  DeviceTypesModStatus: 'idle',
  DeviceTypesModStatusSet: (DeviceTypesModStatus) => {
    set({
      DeviceTypesModStatus,
    })
  },
  DeviceTypes: [],
  DeviceTypesSet: (DeviceTypes) => {
    set({
      DeviceTypes,
    })
  },
  DeviceTypesLookup: [],
  DeviceTypesLookupSet: (DeviceTypesLookup) => {
    set({
      DeviceTypesLookup,
    })
  },
}))
