import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import Modal from 'components/Modal'
import TSTable from 'components/Tanstack'
import { ToggleMenu } from 'components/Layout/ToggleMenu'

import MerchantDevicesUpdateModalForm from '../../Tabs/Device/DeviceEditForm/Form'
import InnerWrapper from '../../Tabs/InnerWrapper'
import { useMerchantDevicesTable } from './useMerchantDevicesTable'
import { useMerchantDevices } from './useMerchantDevices'

const Index = (): JSX.Element => {
  const { merchantDevices, merchantDevicesModStatus } = useMerchantDevices()

  const {
    merchantDevicesActionModal,
    merchantDevicesSelected,
    merchantDevicesTableData,
    merchantDevicesTableColumns,
    handleMemberActionModalClose,
  } = useMerchantDevicesTable()

  return (
    <>
      <InnerWrapper title="Devices">
        {/* FAIL */}
        {merchantDevicesModStatus === 'fail' ? (
          <NoResults
            header="Failed to load data"
            subtitle="Please refresh the page and try again, or contact
        your system administrator"
            noImage
          />
        ) : null}

        {/* LOADING */}
        {merchantDevicesModStatus === 'loading' ? <Spinner /> : null}

        {/* SUCCESS */}
        {merchantDevices.length && merchantDevicesModStatus === 'success' ? (
          <TSTable.Table
            data={merchantDevicesTableData}
            columns={merchantDevicesTableColumns}
          />
        ) : null}

        {/* EMPTY */}
        {!merchantDevices.length && merchantDevicesModStatus === 'success' ? (
          <NoResults
            header="No available data"
            subtitle="There is no available data to display"
            noImage
          />
        ) : null}
      </InnerWrapper>

      {/* UPDATE MERCHANT DEVICE */}
      {merchantDevicesActionModal === 'merchant_devices_preview' &&
      merchantDevicesSelected ? (
        <Modal
          open={true}
          title="Update Merchant Device"
          onClose={handleMemberActionModalClose}
          noFooter
        >
          <MerchantDevicesUpdateModalForm
            onCloseModal={handleMemberActionModalClose}
            selectedMerchantDevice={merchantDevicesSelected}
          />
        </Modal>
      ) : null}
    </>
  )
}

export default Index
