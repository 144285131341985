import { http } from 'utils'

import { MerchantType } from 'types/transactions'
import { MerchantLookupTypes } from 'types/merchanTxTypes'
import {
  MerchantProfileCreateTypes,
  MerchantProfileTypes,
  MerchantDataTypes,
  MerchantTransactionTypes,
  MerchantIdentificationTypes,
  MerchantIdentificationStatusesTypes,
  MerchantIdentificationTypesTypes,
  MerchantDeviceTypes,
  MerchantBatchTypes,
  CreateMerchantServiceTypes,
  UpdateMerchantServiceTypes,
  CreateMerchantIdentificationServiceTypes,
  CreateMerchantDeviceServiceTypes,
  UpdateMerchantDeviceServiceTypes,
  UpdateMerchantIndetificationStatusesTypes,
  MerchantTransactionFilterParamsTypes,
  MerchantIdentificationFilterParamsTypes,
} from 'types/merchant'

import { formatDateToShort } from 'services/helpers'
/**
 * Get list of Merchants.
 *
 * @param token access token
 * @returns array of merchants
 */
export async function getMerchants(): Promise<MerchantType[]> {
  const url = '/merchant'
  const response = await http.get<{ merchant: MerchantType[] }>(url)
  const items = response.data.merchant.map((record: MerchantType) => record)
  return items
}

export async function getMerchantsLookup(
  merchantQuery: string
): Promise<MerchantType[]> {
  const url = `/merchant${merchantQuery}`
  const response = await http.get<{ merchant: MerchantType[] }>(url)
  const items = response.data.merchant.map((record: MerchantType) => record)
  return items
}

export async function getMerchantsLookupOptions(
  merchantQuery: string
): Promise<MerchantLookupTypes[]> {
  const url = `/merchant${merchantQuery}`
  const response = await http.get<{ merchant: MerchantLookupTypes[] }>(url)
  const items = response.data.merchant.map(
    (record: MerchantLookupTypes) => record
  )
  return items
}

export async function getMerchantsTraderLookupOptions(
  merchantQuery: string
): Promise<MerchantLookupTypes[]> {
  const url = `/merchant/trader${merchantQuery}`
  const response = await http.get<{ merchants: MerchantLookupTypes[] }>(url)
  const items = response.data.merchants.map(
    (record: MerchantLookupTypes) => record
  )
  return items
}

export async function getMerchantsTraderLookupOptionsTX(
  merchantQuery: string
): Promise<MerchantLookupTypes[]> {
  const url = `/merchant${merchantQuery}`
  const response = await http.get<{ merchants: MerchantLookupTypes[] }>(url)
  const items = response.data.merchants.map(
    (record: MerchantLookupTypes) => record
  )
  return items
}


export async function getMerchantProfileAPI(
  merchantTraderId?: string,
  merchantName?: string,
  merchantCode?: string,
  deviceCode?: string,
  externalId?: string
): Promise<MerchantProfileTypes[]> {
  let url = `/merchant/profiles`
  const params = new URLSearchParams()

  if (merchantTraderId) {
    params.set('merchantTraderId', merchantTraderId)
  }

  if (merchantName) {
    params.set('merchantName', merchantName.toString())
  }

  if (merchantCode) {
    params.set('merchantCode', merchantCode)
  }

  if (deviceCode) {
    params.set('deviceCode', deviceCode.toString())
  }

  if (externalId) {
    params.set('externalId', externalId.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get<{ merchantProfiles: MerchantProfileTypes[] }>(
    url
  )
  const items = response.data.merchantProfiles.map(
    (record: MerchantProfileTypes) => record
  )
  return items
}

export async function getMerchantAPI(
  traderId?: string,
  merchantName?: string,
  merchantCode?: string,
  deviceCode?: string,
  externalId?: string
): Promise<MerchantDataTypes[]> {
  let url = `/merchant`
  const params = new URLSearchParams()

  if (traderId) {
    params.set('traderId', traderId)
  }

  if (merchantName) {
    params.set('merchantName', merchantName.toString())
  }

  if (merchantCode) {
    params.set('merchantCode', merchantCode)
  }

  if (deviceCode) {
    params.set('deviceCode', deviceCode.toString())
  }

  if (externalId) {
    params.set('externalId', externalId.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get<{ merchants: MerchantDataTypes[] }>(url)
  const items = response.data.merchants.map(
    (record: MerchantDataTypes) => record
  )
  return items
}

export async function getMerchantBatchAPI(): Promise<MerchantBatchTypes[]> {
  let url = `/merchant/batches`
  const params = new URLSearchParams()

  // if (countryId) {
  //   params.set('countryId', countryId)
  // }

  url += `?${params.toString()}`

  const response = await http.get<{ merchantBatches: MerchantBatchTypes[] }>(
    url
  )
  const items = response.data.merchantBatches.map(
    (record: MerchantBatchTypes) => record
  )
  return items
}

export async function postCreateMerchantAPI({
  batchId,
  countryId,
  name,
  merchantCode,
  externalId,
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  suburb,
  city,
  postCode,
}: CreateMerchantServiceTypes): Promise<MerchantProfileCreateTypes> {
  const url = '/merchant/create'
  const data = {
    batchId,
    countryId,
    name,
    merchantCode,
    externalId,
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    suburb,
    city,
    postCode,
  }
  const response = await http.post(url, data)
  return response.data
}

export async function patchUpdateMerchantAPI({
  traderId,
  name,
  merchantCode,
  externalId,
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  suburb,
  city,
  postCode,
}: UpdateMerchantServiceTypes): Promise<void> {
  const url = '/merchant/update'
  const data = {
    traderId,
    name,
    merchantCode,
    externalId,
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    suburb,
    city,
    postCode,
  }
  return await http.patch(url, data)
}

export async function getMerchantTransactionAPI(
  merchantTraderId: number
): Promise<MerchantTransactionTypes[]> {
  let url = `merchant/transactions`
  const params = new URLSearchParams()
  const startDate = formatDateToShort(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))

  if (merchantTraderId) {
    params.set('storeId', String(merchantTraderId))
  }

  url += `?${params.toString()}`
  url += `&startDate=${startDate.toString()}`

  const response = await http.get<{ transactions: MerchantTransactionTypes[] }>(
    url
  )
  const items = response.data.transactions.map(
    (record: MerchantTransactionTypes) => record
  )
  return items
}

export async function getMerchantTransactionFilteredAPI(
  merchantTraderId: number,
  filterParams: MerchantTransactionFilterParamsTypes
): Promise<MerchantTransactionTypes[]> {
  let url = `merchant/transactions`
  const params = new URLSearchParams()
  params.set('storeId', String(merchantTraderId))
  if (filterParams.transactionTypeId)
    params.set('transactionTypeId', String(filterParams.transactionTypeId))
  if (filterParams.startDate)
    params.set('startDate', String(filterParams.startDate))
  if (filterParams.endDate) params.set('endDate', String(filterParams.endDate))

  url += `?${params.toString()}`
  const response = await http.get<{ transactions: MerchantTransactionTypes[] }>(
    url
  )
  const items = response.data.transactions.map(
    (record: MerchantTransactionTypes) => record
  )
  return items
}

export async function getMerchantIdentificationAPI(
  merchantTraderId: number
): Promise<MerchantIdentificationTypes[]> {
  let url = `/idents/${merchantTraderId}`
  const params = new URLSearchParams()

  url += `?${params.toString()}&pSize=50`

  const response = await http.get<{ idents: MerchantIdentificationTypes[] }>(
    url
  )
  const items = response.data.idents.map(
    (record: MerchantIdentificationTypes) => record
  )
  return items
}

export async function getMerchantIdentificationFilteredAPI(
  merchantTraderId: number,
  filterParams: MerchantIdentificationFilterParamsTypes
): Promise<MerchantIdentificationTypes[]> {
  let url = `/idents/${merchantTraderId}`
  const params = new URLSearchParams()
  params.set('traderId', String(merchantTraderId))
  if (filterParams.identificationTypeId)
    params.set('identTypeId', String(filterParams.identificationTypeId))
  if (filterParams.identificationStatusId)
    params.set('identStatusId', String(filterParams.identificationStatusId))

  url += `?${params.toString()}&pSize=50`

  const response = await http.get<{ idents: MerchantIdentificationTypes[] }>(
    url
  )
  const items = response.data.idents.map(
    (record: MerchantIdentificationTypes) => record
  )
  return items
}

export async function getMerchantIdentificationStatusesAPI(): Promise<
  MerchantIdentificationStatusesTypes[]
> {
  let url = `/idents/status`
  const params = new URLSearchParams()

  url += `?${params.toString()}`

  const response = await http.get<{
    identStatus: MerchantIdentificationStatusesTypes[]
  }>(url)
  const items = response.data.identStatus.map(
    (record: MerchantIdentificationStatusesTypes) => record
  )
  return items
}

export async function getMerchantIdentificationTypesAPI(): Promise<
  MerchantIdentificationTypesTypes[]
> {
  let url = `/idents/type`
  const params = new URLSearchParams()
  url += `?${params.toString()}`

  const response = await http.get<{
    identType: MerchantIdentificationTypesTypes[]
  }>(url)
  const items = response.data.identType.map(
    (record: MerchantIdentificationTypesTypes) => record
  )
  return items
}

export async function putUpdateMerchantIdentificationStatusesAPI(
  traderId: number,
  {
    ident,
    identTypeId,
    identStatusId,
    identId,
  }: UpdateMerchantIndetificationStatusesTypes
): Promise<void> {
  let url = `/idents/${traderId}/changeStatus`
  const params = new URLSearchParams()
  params.set('ident', ident)
  params.set('identTypeId', String(identTypeId))
  params.set('identStatusId', String(identStatusId))
  params.set('identId', String(identId))
  url += `?${params.toString()}`

  const data = {}
  return await http.put(url, data)
}

export async function postCreateMerchantIdentificationAPI({
  traderId,
  password,
  traderTransIdent,
  transIdentTypeId,
}: CreateMerchantIdentificationServiceTypes): Promise<void> {
  const url = '/idents/create'
  const data = {
    traderId,
    password,
    traderTransIdent,
    transIdentTypeId,
  }
  return await http.post(url, data)
}

export async function getMerchantDeviceAPI(
  merchantTraderId: number
): Promise<MerchantDeviceTypes[]> {
  let url = `/merchant/devices`
  const params = new URLSearchParams()

  if (merchantTraderId) {
    params.set('traderId', String(merchantTraderId))
  }

  url += `?${params.toString()}&pageSize=50`

  const response = await http.get<{ devices: MerchantDeviceTypes[] }>(url)
  const items = response.data.devices.map(
    (record: MerchantDeviceTypes) => record
  )
  return items
}

export async function postCreateMerchantDeviceAPI({
  traderId,
  deviceCode,
  deviceTypeId,
  startDate,
  endDate,
  comments,
  username,
  password,
}: CreateMerchantDeviceServiceTypes): Promise<void> {
  const url = '/merchant/add-device'
  const data = {
    traderId,
    deviceCode,
    deviceTypeId,
    startDate,
    endDate,
    comments,
    username,
    password,  
  }
  return await http.post(url, data)
}

export async function putUpdateMerchantDeviceAPI({
  deviceId,
  deviceCode,
  startDate,
  endDate,
  comments,
  username,
  password,
  deviceTypeId,
}: UpdateMerchantDeviceServiceTypes): Promise<void> {
  const url = '/merchant/update-device'
  const data = {
    deviceId,
    deviceCode,
    startDate,
    endDate,
    comments,
    username,
    password, 
    deviceTypeId, 
  }
  return await http.put(url, data)
}
