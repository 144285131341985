import { AxiosResponse } from 'axios'
import { HistoryType } from 'types/history'
import {
  MemberData,
  MemberDetail,
  MemberStatuses,
  MemberSubsquadDetail,
  PasswordResetBody,
  PasswordForgottenBody,
  UpdateStatus,
  UpdateMember,
  MemberDetailUpdateServiceTypes,
  GetMemberIdentificationsTypes,
  MemberIdentificationsTypes,
  UpdateMemberIdentificationsStatusTypes,
  MemberIdentificationsStatusTypes,
} from 'types/member'
import { http } from 'utils'

import { THORSTATUS } from 'constants/thorStatus'
import { urlSafeDecode } from '@aws-amplify/core'

const mockAPI = process.env.REACT_APP_API_URL

export async function updateMemberDetailAPI(
  {
    firstName,
    lastName,
    birthDate,
    genderId,
    email,
    mobile,
    enrollmentTraderId,
    enrollmentTraderName,
    address1,
    address2,
    address3,
    address4,
    suburb,
    state,
    city,
    postCode,
    country,
  }: MemberDetailUpdateServiceTypes,
  traderId: number
): Promise<void> {
  const url = `/members/${traderId}`
  const data = {
    firstName,
    lastName,
    birthDate,
    genderId,
    email,
    mobile,
    enrollmentTraderId,
    enrollmentTraderName,
    address1,
    address2,
    address3,
    address4,
    suburb,
    state,
    city,
    postCode,
    country,
  }
  return await http.patch(url, data)
}

// OLD

export async function getMembers(
  // token: string,
  firstName?: string,
  lastName?: string,
  traderId?: string,
  cardNumber?: string,
  email?: string,
  phoneNumber?: string
): Promise<MemberData[]> {
  let url = '/members'
  const params = new URLSearchParams()

  if (firstName) {
    params.set('firstName', firstName)
  }

  if (lastName) {
    params.set('lastName', lastName)
  }

  if (traderId) {
    params.set('traderId', traderId)
  }

  if (cardNumber) {
    params.set('cardNumber', cardNumber)
  }

  if (email) {
    params.set('email', decodeURIComponent(email))
  }

  if (phoneNumber) {
    params.set('phoneNumber', phoneNumber)
  }

  url += `?${params.toString()}`

  const response = await http.get<{ members: MemberData[] }>(url)

  const items = response.data.members.map((record: MemberData) => ({
    traderId: record.traderId,
    cardNumber: record.cardNumber,
    firstName: record.firstName,
    lastName: record.lastName,
    dateOfBirth: record.dateOfBirth,
    postcode: record.postcode,
    status: record.status,
    cardStatus: record.cardStatus,
    creditBalance: record.creditBalance,
    loyaltyBalance: record.loyaltyBalance,
    country: record.country,
    activeVoucherCount: record.activeVoucherCount
  }))

  return items
}

/**
 * Get member's details.
 *
 * @param traderId trader ID
 * @returns member details
 */

export async function getMemberDetail(traderId: string): Promise<MemberDetail> {
  const response = (await http.get<MemberDetail>('/members/' + traderId)).data

  const filterThorStatus = THORSTATUS.filter(
    (item) => item.id === response.memberThorStatusId
  )
  const thorStatusName =
    filterThorStatus.length > 0 ? filterThorStatus[0].name : 'n/a'

  return {
    outcomeCode: response.outcomeCode,
    outcomeMessage: response.outcomeMessage,
    outcomeUserMessage: response.outcomeUserMessage,
    traderId: response.traderId,
    email: response.email,
    memberStatusId: response.memberStatusId,
    memberStatus: response.memberStatus,
    memberThorStatusId: response.memberThorStatusId,
    memberThorStatusName: thorStatusName,
    firstName: response.firstName,
    lastName: response.lastName,
    cardNumber: response.cardNumber,
    mobile: response.mobile,
    enrollmentDate: response.enrollmentDate,
    enrollmentTraderId: response.enrollmentTraderId,
    traderGroupId: response.traderGroupId,
    gender: response.gender,
    genderId: response.genderId,
    birthDate: response.birthDate,
    channelEmail: response.channelEmail,
    channelApp: response.channelApp,
    channelSMS: response.channelSMS,
    channelpostal: response.channelpostal,
    loyaltyBalance: response.loyaltyBalance,
    creditBalance: response.creditBalance,
    traderType: response.traderType,
    enrollmentTraderName: response.enrollmentTraderName,
    lifestageSegment: response.lifestageSegment,
    address1: response.address1,
    address2: response.address2,
    address3: response.address3,
    address4: response.address4,
    suburb: response.suburb,
    state: response.state,
    city: response.city,
    country: response.country,
    postCode: response.postCode,
    flInfo: response.flInfo,
    flMarketing: response.flMarketing,
    flNewsletter: response.flNewsletter,
    flInterest: response.flInterest,
    flThirdparty: response.flThirdparty,
    flCompetition: response.flCompetition,
    lastModifiedDate: response.lastModifiedDate,
    tncVersion: response.tncVersion,
    privacyVersion: response.privacyVersion,
    ranking: response.ranking,
    rankingId: response.rankingId,
    promoCode: response.promoCode,
    countryIsoCode: response.countryIsoCode,
    mailingPreferenceName: response.mailingPreferenceName,
  }
}

/**
 * Get member's subsquad.
 *
 * @param traderId trader ID
 * @returns member details
 */

export async function getMemberSubsquad(
  traderId: string
): Promise<MemberSubsquadDetail> {
  const response = (
    await http.get<MemberSubsquadDetail>(`/members/${traderId}/sub-squad`)
  ).data

  return {
    status: response.status,
    groupUrn: response.groupUrn,
    createDate: response.createDate,
    groupId: response.groupId,
    groupName: response.groupName,
    flActive: response.flActive,
  }
}

// <------------- HISTORY ------------------>

/**
 * Get member's history.
 *
 * @param traderId trace ID
 * @param token access token
 * @param startDate start date
 * @param endDate end date
 * @param pSize parameter size
 * @param pNumber parameter number
 * @returns array of history
 */
export async function getHistory(
  traderId: number,
  editedBy?: string,
  startDate?: string | null,
  endDate?: string | null,
  pSize?: number,
  pNumber?: number
): Promise<HistoryType[]> {
  let url = `/members/${traderId}/history`
  const params = new URLSearchParams()

  if (editedBy) {
    params.set('editedBy', editedBy)
  }

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    params.set('endDate', endDate)
  }

  if (pSize) {
    params.set('pSize', pSize.toString())
  }

  if (pNumber) {
    params.set('pNumber', pNumber.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get<{ memberHistory: HistoryType[] }>(url)

  const items = response.data.memberHistory.map((record: HistoryType) => ({
    memberHistoryId: record.memberHistoryId,
    traderId: record.traderId,
    dateChanged: record.dateChanged,
    note: record.note,
    changedFields: record.changedFields,
    oldValues: record.oldValues,
    newValues: record.newValues,
    editedBy: record.editedBy,
  }))

  return items
}

// <-------------- FORGOTTEN PASSWORD ------------------->

/**
 * request forgotten password.
 *
 * @param ident : email address
 * @param identTypeId : 5
 * @returns array of history
 */
export async function resetPassword({
  ident,
  identTypeId,
}: PasswordForgottenBody): Promise<AxiosResponse> {
  const url = '/password/forgotten'

  // TODO: uncomment and remove mockAPi and mockAuthToken when endpoint is available.
  // const response = await post( url, data,
  //   {
  //     'headers': {
  //       'Authorization': token,
  //     },
  //   },
  // );

  const response = http.post(url, { ident, identTypeId })

  return response
}

// <-------------- GET STATUS ------------------->

/**
 * request update member.
 * @param traderId
 * @returns
 */
export async function getMemberStatusesAPI({
  traderId,
}: {
  traderId: string
}): Promise<MemberStatuses[]> {
  const url = `/members/${traderId}/new-status`
  const response = await http.get<{ statuses: MemberStatuses[] }>(url)
  const items = response.data.statuses.map((statuses: MemberStatuses) => ({
    statusId: statuses.statusId,
    statusName: statuses.statusName,
  }))
  return items
}

// <-------------- UPDATE STATUS 2 ------------------->

/**
 * request update status.
 * @param traderId
 * @param action : cancel / suspend
 * @returns array of history
 */
export async function updateMemberStatusAPI({
  traderId,
  newStatusId,
}: {
  traderId: string
  newStatusId: string
}): Promise<AxiosResponse> {
  const url = `/members/${traderId}/new-status?newStatusId=${newStatusId}`
  const response = http.post(url)
  return response
}

// <-------------- UPDATE STATUS ------------------->

/**
 * request update status.
 * @param traderId
 * @param action : cancel / suspend
 * @returns array of history
 */
export async function updateStatus({
  traderId,
  action,
}: UpdateStatus): Promise<AxiosResponse> {
  const url = `/members/${traderId}/${action}`

  // TODO: uncomment and remove mockAPi and mockAuthToken when endpoint is available.
  // const response = await post( url, data,
  //   {
  //     'headers': {
  //       'Authorization': token,
  //     },
  //   },
  // );

  const response = http.post(url)

  return response
}

// <-------------- UPDATE DETAILS ------------------->

/**
 * request update member.
 * @param traderId
 * @param body
 * @returns
 */
export async function updateMember({
  traderId,
  body,
}: UpdateMember): Promise<AxiosResponse> {
  const url = `/members/${traderId}`
  console.log(url, body)
  const response = http.patch(url, JSON.stringify(body))
  return response
}

export async function getMemberIdentificationsAPI({
  traderId,
  ownershipMode,
  ident,
  identTypeId,
  identStatusId,
  accountId,
  pSize,
  pNumber,
}: GetMemberIdentificationsTypes): Promise<MemberIdentificationsTypes[]> {
  let url = `/idents/${traderId}`
  const params = new URLSearchParams()

  if (ownershipMode) {
    params.set('ownershipMode', ownershipMode.toString())
  }

  if (ident) {
    params.set('ident', ident)
  }

  if (identTypeId) {
    params.set('identTypeId', identTypeId.toString())
  }

  if (identStatusId) {
    params.set('identStatusId', identStatusId.toString())
  }

  if (accountId) {
    params.set('accountId', accountId.toString())
  }

  if (pSize) {
    params.set('pSize', pSize.toString())
  }

  if (pNumber) {
    params.set('pNumber', pNumber.toString())
  }

  url += `?${params.toString()}`
  const response = await http.get<{
    idents: MemberIdentificationsTypes[]
  }>(url)
  const items = response.data.idents.map((record) => record)

  return items
}

export async function updateMemberIdentificationsStatusAPI({
  traderId,
  ident,
  identTypeId,
  identStatusId,
}: UpdateMemberIdentificationsStatusTypes): Promise<MemberIdentificationsStatusTypes> {
  let url = `/idents/${traderId}/changeStatus`
  const params = new URLSearchParams()

  params.set('ident', ident)
  params.set('identTypeId', identTypeId.toString())
  params.set('identStatusId', identStatusId.toString())

  url += `?${params.toString()}`
  const response = await http.put(url)

  return response.data
}
