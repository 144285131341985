import * as Yup from 'yup'

export const CreateMerchantDeviceSchemas = Yup.object().shape({
  traderId: Yup.number(),
  deviceCode: Yup.number()

    .typeError('Please enter number value only')
    .required('This field is required.')
    // .notRequired()
    // .min(0)
    // .max(100)
    .moreThan(-1, 'Negative values not accepted'),

  deviceTypeId: Yup.number().required('This field is required.'),
  startDate: Yup.string(),
  endDate: Yup.string(),
  comments: Yup.string().notRequired(),
  username: Yup.string().notRequired(),
  password: Yup.string().notRequired(),
})
