import { http } from 'utils'

import {
  MembershipsAPITypes,
  MembershipsFetchParamsTypes,
  TraderGroupsAPITypes,
} from 'types/memberships'
import {
  MembershipsAPISchemas,
  TraderGroupsAPISchemas,
} from 'schemas/membershipsSchemas'

import { DATASTATUS } from 'constants/status'

import { MembershipData } from 'types/memberships'

export async function getTraderGroupsAPI(): Promise<TraderGroupsAPITypes> {
  let url = `/groups`
  const params = new URLSearchParams()

  url += `?${params.toString()}`
  const response = await http.get<unknown>(url)
  const validatedData = TraderGroupsAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      data: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function getMembershipsAPI({
  traderId,
  startDate,
  endDate,
  groupId,
  typeId,
  option,
  pSize,
  pNumber,
}: MembershipsFetchParamsTypes): Promise<MembershipsAPITypes> {
  let url = `/groups/${traderId}`
  const params = new URLSearchParams()

  // params.set('flEmail', flEmail)
  // params.set('flSms', flSms)
  // params.set('flPush', flPush)

  if (startDate) {
    params.set('startDate', startDate)
  }

  if (endDate) {
    params.set('endDate', endDate)
  }

  if (groupId) {
    params.set('groupId', groupId.toString())
  }

  if (typeId) {
    params.set('typeId', typeId.toString())
  }

  if (option) {
    params.set('option', option.toString())
  }

  if (pSize) {
    params.set('pSize', pSize.toString())
  }

  if (pNumber) {
    params.set('pNumber', pNumber.toString())
  }

  url += `?${params.toString()}`

  const response = await http.get<unknown>(url)
  const validatedData = MembershipsAPISchemas.safeParse(response.data)
  if (!validatedData.success) {
    return {
      memberGroups: [],
      outcomeMessage: JSON.stringify(validatedData.error),
      ...DATASTATUS,
    }
  }
  return validatedData.data
}

export async function addMembership(
  traderId: number,
  data: MembershipData
): Promise<void> {
  const url = `/groups/${traderId}`

  await http.post(url, data)

  return
}
