import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/Button'
import Input from 'components/SearchInput'
import { Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'

type FormValues = {
  first_name: string
  last_name: string
  trader_id: string
  card_number: string
  email: string
  phone_number: string
  firstname_validation: string
}

type Props = {
  startFetching: (values: FormValues) => void
  startLoading: () => void
  closeSearchForm: () => void
}

const phoneRegex =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

const ValidateSearch = Yup.object().shape({
  email: Yup.string().email('Invalid email'),
  phone_number: Yup.string().matches(phoneRegex, 'Invalid Phone number.'),
  // firstname_validation: Yup.string().when(
  //   [
  //     'first_name',
  //     'last_name',
  //     'trader_id',
  //     'card_number',
  //     'email',
  //     'phone_number',
  //   ],
  //   {
  //     is: (
  //       first_name: string,
  //       last_name: string,
  //       trader_id: string,
  //       card_number: string,
  //       email: string,
  //       phone_number: string
  //     ) => {
  //       if (
  //         first_name &&
  //         !last_name &&
  //         !trader_id &&
  //         !card_number &&
  //         !email &&
  //         !phone_number
  //       )
  //         return true
  //     },
  //     then: Yup.string().required(
  //       'First name must be accompanied with at least one (1) other field. Fill up other field(s).'
  //     ),
  //   }
  // ),
})

const initialValues: FormValues = {
  first_name: '',
  last_name: '',
  trader_id: '',
  card_number: '',
  email: '',
  phone_number: '',
  firstname_validation: '',
}

const MemberSearchForm = ({
  startFetching,
  // getFormikValues,
  startLoading,
  closeSearchForm,
}: Props): JSX.Element => (
  <div className="box-border absolute flex w-3/4 p-4 my-1 text-base bg-white border border-gray-500 pr-96 top-12 z-50">
    <Formik
      initialValues={initialValues}
      validationSchema={ValidateSearch}
      onSubmit={async (values, { setStatus }) => {
        try {
          startLoading()
          closeSearchForm()
          // set fetching to true
          startFetching(values)

          // getFormikValues(values);
        } catch (error) {
          if (error instanceof Error) {
            setStatus(error.message)
          }
        }
      }}
    >
      {({ status, isValid, errors }: FormikProps<FormValues>) => (
        <Form className="w-full mb-4" autoComplete="off">
          <Input
            name="first_name"
            type="text"
            label="First Name"
            // labelClassName="text-gray-500"
          />
          <Input
            name="last_name"
            type="text"
            label="Last Name"
            labelClassName="text-gray-500"
          />
          <Input
            name="trader_id"
            type="text"
            label="Trader ID"
            labelClassName="text-gray-500"
          />
          <Input
            name="card_number"
            type="text"
            label="Card Number"
            labelClassName="text-gray-500"
          />
          <Input
            name="email"
            type="text"
            label="Email"
            labelClassName="text-gray-500"
            className="w-10"
          />
          <Input
            name="phone_number"
            type="text"
            label="Phone Number"
            labelClassName="text-gray-500"
          />
          <Button
            type="submit"
            className="flex px-12 mt-5 text-sm"
            iconClassName="mt-0.5"
            icon={faSearch}
            disabled={!isValid}
            rounded
          >
            <span className="tracking-wider font-bold">Search</span>
          </Button>
          <p>
            <span className="tracking-wider font-light">{status}</span>
          </p>
          <p className="mt-2 text-red">
            <span className="tracking-wider font-light">
              {errors.firstname_validation}
            </span>
          </p>
        </Form>
      )}
    </Formik>
  </div>
)

export default MemberSearchForm
