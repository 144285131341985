import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import Table from 'components/Table'
import Currency from 'components/ui/Currency'
import { ROUTES } from 'constants/routing'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { MemberData } from 'types/member'

const columns = [
  'Identifier',
  'First Name',
  'Last Name',
  // 'DOB',
  // 'Postcode',
  // 'Country',
  'Status',
  'Card Status',
  'CR Bal',
  // 'RWD Bal',
  'Loyalty Bal',
  'Active Vouchers'
]

interface Props {
  postData: MemberData[]
}

const ResultTable = ({ postData }: Props): JSX.Element => {
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1)

  function handlePageClick({ selected }: { selected: number }): void {
    // setLoading(true)
    // setOffset(selected * perPage)
    setCurrentPage(selected + 1)
  }

  return (
    <Table
      data={postData}
      columns={columns}
      pageCount={0}
      showActions
      currentPage={currentPage}
      perPage={10}
      onPageChange={handlePageClick}
      itemCount={postData.length}
    >
      {postData.map((member) => (
        <tr
          key={member.traderId}
          className=" text-black bg-white cursor-pointer hover:bg-gray-600"
          // redirect to member profile
          onClick={() => navigate(`${ROUTES.Members.path}/${member.traderId}/`)}
        >
          <td className="py-4 pl-6">
            <p className="tracking-wider">{member.traderId}</p>
          </td>
          <td className="py-4 pl-6">
            <p className="tracking-wider">{member.firstName}</p>
          </td>
          <td className="py-4 pl-6">
            <p className="tracking-wider">{member.lastName}</p>
          </td>
          {/* <td className="py-2 pl-6">
            <p>{member.dateOfBirth}</p>
          </td>
          <td className="py-2 pl-6">
            <p>{member.postcode}</p>
          </td>
          <td className="py-2 pl-6">
            <p>{member.country}</p>
          </td> */}
          <td className="py-4 pl-6 overflow-hidden whitespace-nowrap overflow-ellipsis">
            <p className="tracking-wider">{member.status}</p>
          </td>
          <td className="py-4 pl-6">
            <p className="tracking-wider">{member.cardStatus}</p>
          </td>
          <td className="py-2 pl-6">
          <p className="tracking-wider">{member.creditBalance}</p>
          </td>
          <td className="py-2 pl-6">
            <p className="tracking-wider">{member.loyaltyBalance}</p>
          </td>
          <td className="py-2 pl-6">
            <p className="tracking-wider">{member.activeVoucherCount}</p>
          </td>
          <td className="float-right py-4 pl-6 mr-4">
            <Icon icon={faChevronRight} />
          </td>
        </tr>
      ))}
    </Table>
  )
}

export default ResultTable
