import InnerWrapper from '../InnerWrapper'
import Modal from 'components/Modal'
import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import TSTable from 'components/Tanstack'
import { ToggleMenu } from 'components/Layout/ToggleMenu'

import BankAccountsStatusUpdateModalForm from './BankAccountsStatusUpdateModalForm'
import { useMemberBankAccounts } from './useMemberBankAccounts'
import { useMemberBankAccountsTable } from './useMemberBankAccountsTable'

import Actions from './Actions'

const Index = (): JSX.Element => {
  const { memberBankAccounts, memberBankAccountsModStatus } =
    useMemberBankAccounts()

  const {
    memberBankAccountsActionModal,
    memberBankAccountsSelected,
    memberBankAccountsTableData,
    memberBankAccountsTableColumns,
    handleMemberActionModalClose,
  } = useMemberBankAccountsTable()

  return (
    <>
      <InnerWrapper title="Bank Accounts">
        {/* FAIL */}
        {memberBankAccountsModStatus === 'fail' ? (
          <NoResults
            header="Failed to load data"
            subtitle="Please refresh the page and try again, or contact
        your system administrator"
            noImage
          />
        ) : null}

        {/* LOADING */}
        {memberBankAccountsModStatus === 'loading' ? <Spinner /> : null}

        {/* SUCCESS */}
        {memberBankAccounts.length &&
        memberBankAccountsModStatus === 'success' ? (
          <>
            <Actions />
            <br />
            <TSTable.Table
              data={memberBankAccountsTableData}
              columns={memberBankAccountsTableColumns}
            />
          </>
        ) : null}

        {/* EMPTY */}
        {!memberBankAccounts.length &&
        memberBankAccountsModStatus === 'success' ? (
          <>
            <Actions /><br></br>
            <NoResults
              header="No available data"
              subtitle="There is no available data to display"
              noImage
            />
          </>
        ) : null}
      </InnerWrapper>

      {/* UPDATE BANK ACCOUNT STATUS */}
      {memberBankAccountsActionModal === 'financials_status_update' &&
      memberBankAccountsSelected ? (
        <Modal
          open={true}
          title="Update Bank Account"
          onClose={handleMemberActionModalClose}
          noFooter
        >
          <BankAccountsStatusUpdateModalForm
            modalClose={handleMemberActionModalClose}
            selectedRowData={memberBankAccountsSelected}
          />
        </Modal>
      ) : null}
    </>
  )
}

export default Index
