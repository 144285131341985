import { ROUTES } from './routing'

import { accessControlSubNavigation } from 'setups/controller'

const projectClient = process.env.REACT_APP_PROJ

export const MerchantTabModulesData = [
  {
    module: 'Transaction',
    route: {
      title: 'Transactions',
      path: ROUTES.MerchantProfileTransactions,
      pathname: 'transactions',
      className:
        'text-gray-500 py-2 mx-3 block hover:text-primary focus:outline-none',
    },
  },
  {
    module: 'Identification',
    route: {
      title: 'Identifications',
      path: ROUTES.MerchantProfileIdentification,
      pathname: 'identifications',
      className:
        'text-gray-500 py-2 mx-3 block hover:text-primary focus:outline-none',
    },
  },
  {
    module: 'Device',
    route: {
      title: 'Devices',
      path: ROUTES.MerchantProfileDevice,
      pathname: 'devices',
      className:
        'text-gray-500 py-2 mx-3 block hover:text-primary focus:outline-none',
    },
  },
  {
    module: 'Memberships',
    route: {
      title: 'Memberships',
      path: ROUTES.MerchantProfileMemberships,
      pathname: 'memberships',
      className:
        'text-gray-500 py-2 mx-3 block hover:text-primary focus:outline-none',
    },
  },
]

export const MERCHANTTABSNAVIGATION = accessControlSubNavigation(
  projectClient,
  MerchantTabModulesData,
  'merchant'
)
