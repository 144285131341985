import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls, formatter } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import {
  MerchantDeviceTypes,
  UpdateMerchantDeviceTypes,
  UpdateMerchantDeviceFormTypes,
  UpdateMerchantDeviceFormSubmitValuesTypes,
  UpdateMerchantDeviceFormSubmitActionsTypes,
} from 'types/merchant'

import useMerchantStore from 'store/merchant'

const UPDATEMERCHANTDEVICEINITVAL: UpdateMerchantDeviceTypes = {
  deviceId: 0,
  deviceCode: '',
  startDate: '',
  endDate: '',
  comments: '',
  username: '',
  password: '',
  deviceTypeId: 0,
}

export const useUpdateDevice = (
  selectedMerchantDevice: MerchantDeviceTypes,
  onCloseModal: () => void
): {
  isLoading: boolean
  initialValues: UpdateMerchantDeviceTypes
  handleSubmitUpdateMerchantDevice: (
    values: UpdateMerchantDeviceFormSubmitValuesTypes,
    actions: UpdateMerchantDeviceFormSubmitActionsTypes
  ) => void
  // handleClearPurchaseTx: () => void
} => {
  const { traderId } = useParams()
  const queryClient = useQueryClient()
  const merchantTraderId = Number(traderId) || 0
  // AUTH TOKEN START
  const authToken = ls.get(LSKEY_TOKEN)
  const decodeAuthToken: { username: string } = jwt_decode(String(authToken))
  const authTokenUsername = decodeAuthToken?.username || ''
  // AUTH TOKEN END
  // MERCHANT CREATE IDENT START
  const updateMerchantDevice = useMerchantStore(
    (state) => state.updateMerchantDevice
  )
  const merchantDeviceUpdateLoading = useMerchantStore(
    (state) => state.merchantDeviceUpdateLoading
  )
  // MERCHANT CREATE IDENT END
  // MISC STATES START
  const [initialValues, initialValuesSet] =
    useState<UpdateMerchantDeviceFormTypes>({
      ...UPDATEMERCHANTDEVICEINITVAL,
      deviceId: selectedMerchantDevice.deviceId,
      deviceCode: selectedMerchantDevice.deviceCode,
      startDate: selectedMerchantDevice.startDate,
      endDate: selectedMerchantDevice.endDate,
      deviceTypeId: selectedMerchantDevice.deviceTypeId,
    })
  // MISC STATES END

  const isLoading = useMemo(() => {
    return merchantDeviceUpdateLoading ? true : false
  }, [merchantDeviceUpdateLoading])

  const handleSubmitUpdateMerchantDevice = useCallback(
    async (
      values: UpdateMerchantDeviceFormSubmitValuesTypes,
      actions: UpdateMerchantDeviceFormSubmitActionsTypes
    ) => {
      try {
        await updateMerchantDevice(merchantTraderId, values)
        toast.success('Merchant Device update successful.')
        queryClient.invalidateQueries({ queryKey: ['useMerchantDevices'] })
        onCloseModal()
        // actions.setSubmitting(false)
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        if (message.outcomeCode === 'HDA_MERCHANT:UPD_DEVICE:4') {
          toast.error(`Merchant Device already exist.`)
        } else {
          toast.error(`Merchant Device error.`)
        }
      }
    },
    [updateMerchantDevice]
  )

  const handleClearPurchaseTx = useCallback(() => {
    initialValuesSet({ ...UPDATEMERCHANTDEVICEINITVAL })
    toast.success('Filter successfully cleared.')
  }, [initialValuesSet])

  return {
    isLoading,
    initialValues,
    handleSubmitUpdateMerchantDevice,
    // handleClearPurchaseTx,
  }
}
