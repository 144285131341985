import { useEffect, useCallback, useState } from "react"
import { useParams } from "react-router-dom"
import { useQuery, useQueryClient } from "react-query"
import { DATASTATUS } from "constants/status"

import { MerchantDevicesFetchParamsTypes, AddMerchantDevicesFetchParamsTypes, UpdateMerchantDevicesFetchParamsTypes } from "types/merchantDevices"
import { useMerchantDevicesStore } from "store/merchantDevices"
import { getMerchantDevicesAPI } from "api/merchantdevices"

export const useMerchantDevices = () => {
    const { traderId } = useParams()
    const merchantTraderId = parseInt(String(traderId) || '0')
    const defaultParams = { traderId: merchantTraderId }

    const merchantDevicesModStatus = useMerchantDevicesStore((state) => state.merchantDevicesModStatus)
    const merchantDevicesModStatusSet = useMerchantDevicesStore((state) => state.merchantDevicesModStatusSet)
    const merchantDevicesModErrors = useMerchantDevicesStore((state) => state.merchantDevicesModErrors)
    const merchantDevicesModErrorsSet = useMerchantDevicesStore((state) => state.merchantDevicesModErrorsSet)
    const merchantDevices = useMerchantDevicesStore((state) => state.merchantDevices)
    const merchantDevicesSet = useMerchantDevicesStore((state) => state.merchantDevicesSet)
    const [merchantDevicesFilterParams, merchantDevicesFiltersParamsSet] = useState<MerchantDevicesFetchParamsTypes>(defaultParams)
    const [isMerchantDevicesFiltered, isMerchantDevicesFilteredSet] = useState<boolean>(false)

    const queryClient = useQueryClient()
    const {
        data: dataMerchantDevices,
        isLoading: isLoadingMerchantDevices,
        isError: isErrorMerchantDevices,
    } = useQuery({
        refetchOnWindowFocus: false,
        queryKey: ['useMerchantDevices', merchantDevicesFilterParams],
        queryFn: async() => {
            try {
                merchantDevicesModStatusSet('loading')
                const response = await getMerchantDevicesAPI(merchantDevicesFilterParams)
                if (response.outcomeCode === DATASTATUS.outcomeCode) throw response
                merchantDevicesModStatusSet('success')
                return response.devices
            } catch (e) {
                merchantDevicesModStatusSet('fail')
                merchantDevicesModErrorsSet(JSON.stringify(e))
            }
        },
    })

    const handleFilter = useCallback(
        async (filterParams: MerchantDevicesFetchParamsTypes) => {
            merchantDevicesFiltersParamsSet(filterParams)
            isMerchantDevicesFilteredSet(true)
        },
        [merchantDevicesFiltersParamsSet, isMerchantDevicesFilteredSet]
    )

    const handleUnfilter = useCallback(() => {
        const test = { traderId: merchantTraderId }
        merchantDevicesFiltersParamsSet(test)
        isMerchantDevicesFilteredSet(false)
    }, [merchantDevicesFiltersParamsSet, isMerchantDevicesFilteredSet])

    useEffect(() => {
        if (dataMerchantDevices) merchantDevicesSet(dataMerchantDevices)
    }, [dataMerchantDevices, merchantDevicesSet])

    return {
        merchantDevicesHandleFilter: handleFilter,
        merchantDevicesHandleUnfilter: handleUnfilter,
        merchantDevicesFilterParams: merchantDevicesFilterParams,
        merchantDevices: merchantDevices,
        merchantDevicesModStatus: merchantDevicesModStatus,
    }
}
