import { http } from 'utils'

import { IcashTopupTxServiceTypes } from 'types/icashTopupTxTypes'
import { IcashTopupReversalTxServiceTypes } from 'types/icashTopupReversalTxTypes'
import { IcashRedemptionTxServiceTypes } from 'types/icashRedemptionTxTypes'
import { IcashRedemptionReversalTxServiceTypes } from 'types/icashRedemptionReversalTxTypes'
import { IcashDonationTxServiceTypes } from 'types/icashDonationTxTypes'
import { IcashDonationRefundTxServiceTypes } from 'types/icashDonationRefundTxTypes'

export async function topUpAPI({
  creditValue,
  externalTransactionId,
  ident,
  cashier,
  guid,
  storeId,
  deviceCode,
  transactionDate,
  comments,
  traceNumber,
  reasonId,
}: IcashTopupTxServiceTypes): Promise<void> {
  const url = '/manual-transaction/top-up'
  const data = {
    creditValue,
    externalTransactionId,
    ident,
    cashier,
    guid,
    storeId,
    deviceCode,
    transactionDate,
    comments,
    traceNumber,
    reasonId,  
  }
  return await http.post(url, data)
}

export async function topUpReversalAPI({
  externalTransactionId,
  ident,
  storeId,
  cashier,
  comments,
  traceNumber,
  reasonId,
}: IcashTopupReversalTxServiceTypes): Promise<void> {
  const url = '/manual-transaction/top-up-reversal'
  const data = {
    externalTransactionId,
    ident,
    storeId,
    cashier,
    comments,
    traceNumber,  
    reasonId,
  }
  return await http.post(url, data)
}

export async function redemptionAPI({
  creditValue,
  externalTransactionId,
  ident,
  pin,
  cashier,
  guid,
  storeId,
  deviceCode,
  transactionDate,
  comments,
  traceNumber,
  reasonId,
}: IcashRedemptionTxServiceTypes): Promise<void> {
  const url = '/manual-transaction/redemption'
  const data = {
    creditValue,
    externalTransactionId,
    ident,
    pin,
    cashier,
    guid,
    storeId,
    deviceCode,
    transactionDate,
    comments,
    traceNumber,
    reasonId,
  }
  return await http.post(url, data)
}

export async function redemptionReversalAPI({
  externalTransactionId,
  ident,
  storeId,
  cashier,
  comments,
  traceNumber,
  reasonId,
}: IcashRedemptionReversalTxServiceTypes): Promise<void> {
  const url = '/manual-transaction/redemption-reversal'
  const data = {
    externalTransactionId,
    ident,
    storeId,
    cashier,
    comments,
    traceNumber,  
    reasonId,
  }
  return await http.post(url, data)
}

export async function donationAPI({
  traderId,
  creditValue,
  externalTransactionId,
  ident,
  storeId,
  deviceCode,
  cashier,
  guid,
  transactionDate,
  comments,
  traceNumber,
  reasonId,
}: IcashDonationTxServiceTypes): Promise<void> {
  let url = '/manual-transaction/donation'
  const params = new URLSearchParams()

  if (traderId) {
    params.set('traderId', traderId.toString())
  }
  url += `?${params.toString()}`

  const data = {
    creditValue,
    externalTransactionId,
    ident,
    cashier,
    guid,
    storeId,
    deviceCode,
    transactionDate,
    comments,
    traceNumber,
    reasonId,
  }
  return await http.post(url, data)
}

export async function donationReversalAPI({
  ident,
  externalTransactionId,
  creditValue,
  fromTraderId,
  // comments,
  // traceNumber,
  // reasonId,
}: IcashDonationRefundTxServiceTypes): Promise<void> {
  const url = '/manual-transaction/donation-refund'
  const data = {
    externalTransactionId,
    ident,
    creditValue,
    fromTraderId,  
    // comments,
    // traceNumber,  
    // reasonId,
  }
  return await http.post(url, data)
}

// OLD API

export async function topUp(
  creditValue: number,
  externalTransactionId: number,
  ident: string
): Promise<void> {
  const url = '/manual-transaction/top-up'

  const data = {
    creditValue,
    externalTransactionId,
    ident,
  }

  return await http.post(url, data)
}

export async function topUpReversal(
  externalTransactionId: number,
  ident: string
): Promise<void> {
  const url = '/manual-transaction/top-up-reversal'

  const data = {
    externalTransactionId,
    ident,
  }

  return await http.post(url, data)
}

export async function redemption(
  creditValue: number,
  externalTransactionId: number,
  ident: string,
  pin: string
): Promise<void> {
  const url = '/manual-transaction/redemption'

  const data = {
    creditValue,
    externalTransactionId,
    ident,
    pin,
  }

  return await http.post(url, data)
}

export async function redemptionReversal(
  externalTransactionId: number,
  ident: string
): Promise<void> {
  const url = '/manual-transaction/redemption-reversal'

  const data = {
    externalTransactionId,
    ident,
  }

  return await http.post(url, data)
}
