import React from 'react'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons'

import Button from 'components/Button'
import useTransactions from './useTransactions'
import { ExportToCSV, ExportToPDF } from './utils-actions';


// const jsonToCSV = <T extends Record<string, any>>(jsonData: T[]) => {
  // const filename = "ex.csv"
  //   if (!jsonData?.length) return;
  
  //   const headers = Object.keys(jsonData[0]);
  
  //   const csvRows = jsonData.map(row => {
  //     return headers.map(field => {
  //       // Type-safe nested property access
  //       const value = field.split('.').reduce((obj: any, key) => {
  //         return (obj && obj[key] !== undefined) ? obj[key] : '';
  //       }, row);
  
  //       // CSV formatting
  //       let str = value === null || value === undefined ? '' : String(value);
  //       if (/[",\n]/.test(str)) {
  //         str = `"${str.replace(/"/g, '""')}"`;
  //       }
  //       return str;
  //     });
  //   });
  
  // // Combine headers and rows
  // const csvContent = [
  //   headers.join(','),
  //   ...csvRows.map(row => row.join(','))
  // ].join('\n');
  
  // Download
  // const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  // const url = URL.createObjectURL(blob);
  // const link = document.createElement('a');
  // link.setAttribute('hidden', '');
  // link.setAttribute('href', url);
  // link.setAttribute('download', filename);
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);
// }

function TransactionsReportFilter(): React.ReactElement {
  const { traderId } = useParams()
  const { transactions, transactionsLoading } = useTransactions({
    memberTraderId: String(traderId),
  })

  return (
    <div className="flex flex-wrap items-end mt-4 mb-5 gap-2">
      <Button
        type="button"
        icon={faArrowLeft}
        className="ml-1 rounded-lg"
        iconRight={false}
      >
        
        <NavLink to={`/member-profile/${traderId}/transactions`} >
          <span className="tracking-wider font-bold">Back</span>
        </NavLink>
      </Button>

      <Button 
      type="button"
      icon={faDownload}
      className="ml-1 rounded-lg"
      iconRight={false}
      onClick={ExportToPDF}
      disabled={transactionsLoading ? true : false}
      >
        Download PDF
      </Button>

      <Button 
      type="button"
      icon={faDownload}
      className="ml-1 rounded-lg"
      iconRight={false}
      onClick={() => ExportToCSV(transactions)}
      disabled={transactionsLoading ? true : false}
      >
        Export to CSV
      </Button>

    </div>
  )
}

export default TransactionsReportFilter