import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Colors from 'colors.json'
import { Form, Formik } from 'formik'

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DatetimePickerInput from 'components/DatetimePickerInput'
import Input from 'components/Input'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import { UpdateMerchantDeviceSchemas } from 'schemas/updateMerchantDeviceSchemas'
import { MerchantDeviceTypes } from 'types/merchant'

import { useUpdateDevice } from './useUpdateDevice'
import { useMerchantDeviceTypes } from '../../Device/useMerchantDeviceTypes'

interface PropsTypes {
  onCloseModal: () => void
  selectedMerchantDevice: MerchantDeviceTypes
}

const Index: React.FC<PropsTypes> = ({
  onCloseModal,
  selectedMerchantDevice,
}): React.ReactElement => {
  // STATES
  const { merchantDeviceTypesOptions, merchantDeviceTypesModStatus } =
    useMerchantDeviceTypes()
  const merchantDeviceId = selectedMerchantDevice.deviceId
  const { isLoading, initialValues, handleSubmitUpdateMerchantDevice } =
    useUpdateDevice(selectedMerchantDevice, onCloseModal)

  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  if (isLoading) return <></>

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UpdateMerchantDeviceSchemas}
      onSubmit={handleSubmitUpdateMerchantDevice}
    >
      {({ values, isSubmitting, setFieldValue, errors, touched }) => {
        return (
          <Form className="flex flex-col">
            <div className="mb-4">
              <div className="flex  gap-4 items-center">
                <Input
                  name="deviceCode"
                  label="Device Code"
                  containerClassName="w-full"
                  type="number"
                  disabled
                />
              </div>
              <Dropdown
                title="Device Types"
                defaultValue={
                  merchantDeviceTypesOptions.filter(
                    (item) =>
                      parseInt(item.value) ===
                      selectedMerchantDevice.deviceTypeId
                  )[0]
                }
                options={merchantDeviceTypesOptions}
                className="w-full tracking-wider mb-4"
                borderColor="#C4C4C4"
                onSelect={(data) =>
                  setFieldValue('deviceTypeId', String(data.value))
                }
              />
              <div className="flex gap-4 justify-between">
                <DatetimePickerInput
                  title={'Start Date'}
                  setFieldName="startDate"
                  setFieldValue={setFieldValue}
                  showTimeSelect={false}
                  defaultDateValue={values.startDate}
                />
                <DatetimePickerInput
                  title={'End Date'}
                  setFieldName="endDate"
                  setFieldValue={setFieldValue}
                  showTimeSelect={false}
                  defaultDateValue={values.endDate}
                />
              </div>
            </div>
            <div className="flex gap-4 items-center">
              <Input
                name="comments"
                label="Comment"
                containerClassName="w-full"
                type="input"
              />
            </div>
            <div className="flex gap-4 items-center">
              <Input
                name="username"
                label="Device Username"
                containerClassName="w-full"
                type="input"
              />
            </div>
            <div className="relative">
              <Input
                name="password"
                type={showPassword ? 'text' : 'password'}
                label="Device Password"
                labelClassName="text-gray-500"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-3 text-gray-500"
                style={{ top: '2.3rem' }}
                aria-label="Toggle password visibility"
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            <footer className="flex justify-end pb-4 pl-4 pr-4">
              <Button
                type="submit"
                className="mr-2 rounded bg-primary"
                disabled={isSubmitting}
              >
                <span className="tracking-wider font-bold">Submit</span>
              </Button>
              <Button
                className="bg-white border rounded border-primary"
                onClick={onCloseModal}
                style={{ color: Colors.primary }}
              >
                <span className="tracking-wider font-bold">Cancel</span>
              </Button>
            </footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Index
