import create from 'zustand'

import { StatusTypes, StatusErrorTypes } from 'types/status'
import { MembershipsDataTypes, TraderGroupsDataTypes, } from 'types/memberships'

interface MembershipsStoreTypes {
  membershipsModErrors: StatusErrorTypes
  membershipsModErrorsSet: (membershipsModErrors: StatusErrorTypes) => void
  membershipsModStatus: StatusTypes
  membershipsModStatusSet: (membershipsStatus: StatusTypes) => void
  memberships: MembershipsDataTypes[]
  membershipsSet: (memberships: MembershipsDataTypes[]) => void
  membershipsLookup: MembershipsDataTypes[]
  membershipsLookupSet: (memberships: MembershipsDataTypes[]) => void
}

export const useMembershipsStore = create<MembershipsStoreTypes>((set) => ({
  membershipsModErrors: '',
  membershipsModErrorsSet: (membershipsModErrors) => {
    set({
      membershipsModErrors,
    })
  },
  membershipsModStatus: 'idle',
  membershipsModStatusSet: (membershipsModStatus) => {
    set({
      membershipsModStatus,
    })
  },
  memberships: [],
  membershipsSet: (memberships) => {
    set({
      memberships,
    })
  },
  membershipsLookup: [],
  membershipsLookupSet: (membershipsLookup) => {
    set({
      membershipsLookup,
    })
  },
}))

interface MembershipsLookupStoreTypes {
  membershipsLookupModErrors: StatusErrorTypes
  membershipsLookupModErrorsSet: (
    membershipsLookupModErrors: StatusErrorTypes
  ) => void
  membershipsLookupModStatus: StatusTypes
  membershipsLookupModStatusSet: (membershipsLookupStatus: StatusTypes) => void
  membershipsLookup: MembershipsDataTypes[]
  membershipsLookupSet: (membershipsLookup: MembershipsDataTypes[]) => void
}

export const useMembershipsLookupStore = create<MembershipsLookupStoreTypes>(
  (set) => ({
    membershipsLookupModErrors: '',
    membershipsLookupModErrorsSet: (membershipsLookupModErrors) => {
      set({
        membershipsLookupModErrors,
      })
    },
    membershipsLookupModStatus: 'idle',
    membershipsLookupModStatusSet: (membershipsLookupModStatus) => {
      set({
        membershipsLookupModStatus,
      })
    },
    membershipsLookup: [],
    membershipsLookupSet: (membershipsLookup) => {
      set({
        membershipsLookup,
      })
    },
  })
)

interface TraderGroupsStoreTypes {
  TraderGroupsModErrors: StatusErrorTypes
  TraderGroupsModErrorsSet: (TraderGroupsModErrors: StatusErrorTypes) => void
  TraderGroupsModStatus: StatusTypes
  TraderGroupsModStatusSet: (TraderGroupsStatus: StatusTypes) => void
  TraderGroups: TraderGroupsDataTypes[]
  TraderGroupsSet: (TraderGroups: TraderGroupsDataTypes[]) => void
  TraderGroupsLookup: TraderGroupsDataTypes[]
  TraderGroupsLookupSet: (TraderGroups: TraderGroupsDataTypes[]) => void
}

export const useTraderGroupsStore = create<TraderGroupsStoreTypes>((set) => ({
  TraderGroupsModErrors: '',
  TraderGroupsModErrorsSet: (TraderGroupsModErrors) => {
    set({
      TraderGroupsModErrors,
    })
  },
  TraderGroupsModStatus: 'idle',
  TraderGroupsModStatusSet: (TraderGroupsModStatus) => {
    set({
      TraderGroupsModStatus,
    })
  },
  TraderGroups: [],
  TraderGroupsSet: (TraderGroups) => {
    set({
      TraderGroups,
    })
  },
  TraderGroupsLookup: [],
  TraderGroupsLookupSet: (TraderGroupsLookup) => {
    set({
      TraderGroupsLookup,
    })
  },
}))
