import PrivateRoute from 'components/PrivateRoute'
import { ROUTES } from 'constants/routing'
import { Routes } from 'react-router'

import TransactionsReport from 'views/MemberProfile/Tabs/TransactionsReport'

const HDAReports = (): JSX.Element => {
  return (
    <Routes>
      <PrivateRoute
      path={ROUTES.HDAReports_MemberTransactionsReports.path}
      element={<TransactionsReport />}
      permissions={ROUTES.HDAReports_MemberTransactionsReports.permissionIds}
      />
    </Routes>
  )
}
export default HDAReports
