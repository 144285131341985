import * as Yup from 'yup'

export const IcashDonationRefundTxSchemas = Yup.object().shape({
  externalTransactionId: Yup.number(),
  ident: Yup.string(),
  creditValue: Yup.number(),
  fromTraderId: Yup.number(),
  // comments: Yup.string().optional(),
  // traceNumber: Yup.number().optional(),
  // reasonId: Yup.number().optional(),
})
