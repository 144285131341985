import {
  faChevronDown,
  faChevronUp,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState, useCallback } from 'react'

export const ToggleMenu = ({
  type,
  label,
  isTriggeToggle,
  children,
}: {
  label: string
  type: 'search'
  isTriggeToggle?: boolean
  children: React.ReactNode
}): JSX.Element => {
  const [isToggle, isToggleSet] = useState<boolean>(false)

  const wrapperRef = useRef(null)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useOutsideAlerter = (ref: any): void => {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent): void {
        if (ref.current && !ref.current.contains(event.target)) {
          isToggleSet(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const handleToggle = useCallback(() => {
    isToggleSet(!isToggle)
  }, [isToggle, isToggleSet])

  const handleClose = useCallback(() => {
    if (isToggle) isToggleSet(false)
  }, [isToggle, isToggleSet])

  useEffect(() => {
    if (isTriggeToggle) handleClose()
  }, [isTriggeToggle])

  useOutsideAlerter(wrapperRef)

  return (
    <div className="relative flex flex-col w-full" ref={wrapperRef}>
      <div className="relative table w-full border-separate focus-within:text-gray-500">
        {/* CONTROL BUTTON */}
        <button onClick={handleToggle} className="flex gap-2 items-center">
          <span className="flex gap-2 items-center tracking-wider">
            {type === 'search' ? (
              <Icon
                icon={faSearch}
                className="text-primary text-opacity-20 w-3 h-3"
              />
            ) : null}
            <span>{label}</span>
          </span>
          <Icon
            icon={isToggle ? faChevronUp : faChevronDown}
            className="text-gray-500"
          />
        </button>

        {/* //   {isInputFocus ? (
    //     <>
    //       {isMemberLookup ? <SearchTips /> : null}
    //       {isSubsquadLookup ? <SubSquadSearchTips /> : null}
    //       {isMerchantLookup ? <MerchantSearchTips /> : null}
    //     </>
    //   ) : null} */}

        {isToggle ? (
          <div className="absolute left-0 flex w-1/2 p-4 my-1 text-base bg-white bg-opacity-90 rounded-tr-lg rounded-br-lg rounded-bl-lg border border-primary border-opacity-20" style={{ zIndex: 1 }}>
            <div className="p-4 bg-white rounded-lg w-full">{children}</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
