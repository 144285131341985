import Modal from 'components/Modal'
import Membership from 'views/MerchantProfile/ManualTx/Membership'

type Props = {
  title: string | undefined
  open: boolean
  onClose: () => void
}

const ModalMembership = ({ title, open, onClose }: Props): JSX.Element => {
  return (
    <Modal open={open} title={title} onClose={onClose} noFooter>
      <Membership onClose={onClose} open={open} />
    </Modal>
  )
}

export default ModalMembership
