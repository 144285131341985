import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

// AUTH TOKEN START
import jwt_decode from 'jwt-decode'
import { ls, formatter } from 'utils'
import { LSKEY_TOKEN } from 'constants/app'
// AUTH TOKEN END

import { donationAPI } from 'api/icash'
import { MerchantLookupTypes } from 'types/merchanTxTypes'
import {
  IcashDonationTxFormTypes,
  IcashDonationTxFormSubmitValuesTypes,
  IcashDonationTxFormSubmitActionsTypes,
} from 'types/icashDonationTxTypes'
import { MemberDetail } from 'types/member'
import useMemberStore from 'store/member'
import { getTransactionsV2API } from 'api/transactions'
import { useParams } from 'react-router-dom'

const DONATIONTXINITVAL: IcashDonationTxFormTypes = {
  traderId: 0,
  creditValue: 0,
  externalTransactionId: 0,
  ident: '',
  cashier: '',
  guid: '',
  storeId: 0,
  deviceCode: '',
  transactionDate: '',
  comments: '',
  traceNumber: 0,
  reasonId: 0,
}

export const useIcashDonationTx = (
  onCloseModal: () => void
): {
  member: MemberDetail | undefined
  isLoading: boolean
  initialValues: IcashDonationTxFormTypes
  isMerchantLookupToggle: boolean
  handleIsMerchantLookupToggle: () => void
  handleMerchantSet: (merchant: MerchantLookupTypes[]) => void
  handleSubmitIcashDonationTx: (
    values: IcashDonationTxFormSubmitValuesTypes,
    actions: IcashDonationTxFormSubmitActionsTypes
  ) => void
  handleClearIcashDonationTx: () => void
} => {
  // AUTH TOKEN START
  const authToken = ls.get(LSKEY_TOKEN)
  const decodeAuthToken: { username: string } = jwt_decode(String(authToken))
  const authTokenUsername = decodeAuthToken?.username || ''
  // AUTH TOKEN END
  // STATES
  const [isMerchantLookupToggle, isMerchantLookupToggleSet] =
    useState<boolean>(false)
  const [isLoading, isLoadingSet] = useState<boolean>(false)
  const [purchaseTxMerchant, purchaseTxMerchantSet] = useState<
    MerchantLookupTypes[]
  >([])
  const member = useMemberStore((state) => state.member)
  const [initialValues, initialValuesSet] =
    useState<IcashDonationTxFormTypes>({
      ...DONATIONTXINITVAL,
      traderId: member?.traderId || 0,
      cashier: authTokenUsername,
      externalTransactionId: formatter.externalIdentification(),
      // ident: String(member?.cardNumber) || '',
    })

  const handleIsMerchantLookupToggle = useCallback(() => {
    isMerchantLookupToggleSet(!isMerchantLookupToggle)
  }, [isMerchantLookupToggle, isMerchantLookupToggleSet])

  const handleMerchantSet = useCallback(
    async (merchant: MerchantLookupTypes[]) => {
      try {
        purchaseTxMerchantSet(merchant)
        toast.success('Merchant Selected')
      } catch (error) {
        toast.error('Something went wrong.')
      }
    },
    [purchaseTxMerchantSet]
  )

  const handleSubmitIcashDonationTx = useCallback(
    async (
      values: IcashDonationTxFormSubmitValuesTypes,
      actions: IcashDonationTxFormSubmitActionsTypes
    ) => {
      isLoadingSet(true)
      try {
        if (String(values.externalTransactionId) === "") {
          values.externalTransactionId = initialValues.externalTransactionId + 1
        }
        const submitValues = {
          ...values,
          storeId: values.storeId === 0 ? null : values.storeId
        }
        await donationAPI(submitValues)
        toast.success('iCash donationAPI successful.')
        onCloseModal()
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error))
        toast.error(message.outcomeUserMessage)
      }
      isLoadingSet(false)
    },
    [isLoadingSet]
  )

  const handleClearIcashDonationTx = useCallback(() => {
    initialValuesSet({ ...DONATIONTXINITVAL })
    toast.success('Filter successfully cleared.')
  }, [initialValuesSet])

  return {
    member,
    isLoading,
    initialValues,
    isMerchantLookupToggle,
    handleIsMerchantLookupToggle,
    handleMerchantSet,
    handleSubmitIcashDonationTx,
    handleClearIcashDonationTx,
  }
}
