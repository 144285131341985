import * as Yup from 'yup'

export const UpdateMerchantDeviceSchemas = Yup.object().shape({
  deviceId: Yup.number(),
  deviceCode: Yup.number()
    .typeError('Please enter number value only')
    .nullable()
    .required('This field is required.')
    // .notRequired()
    // .min(0)
    // .max(100)
    .moreThan(-1, 'Negative values not accepted'),
  startDate: Yup.string(),
  endDate: Yup.string(),
  comments: Yup.string().optional(),
  username: Yup.string().optional(),
  password: Yup.string().optional(),
  deviceTypeId: Yup.number().optional(),
})
