import create from 'zustand'
import { persist } from "zustand/middleware";


interface MemberSearchStateStoreTypes {
  searchArrayString: string | null
  searchArrayStringSet: (searchArrayString: string | null) => void
}

export const MemberSearchStateStore = create<MemberSearchStateStoreTypes>()(
  persist(
    (set) => ({
      searchArrayString: null,
      searchArrayStringSet: (searchArrayString) => {
        set({
          searchArrayString,
        })
      },
    }),
    {
      name: "member-search-state",
    }
  )
);

