import ModaliCashDonation from 'views/MemberProfile/Header/Buttons/ModaliCashDonation'
import ModaliCashDonationRefund from 'views/MemberProfile/Header/Buttons/ModaliCashDonationRefund'
import ModaliCashRedemption from 'views/MemberProfile/Header/Buttons/ModaliCashRedemption'
import ModaliCashRedemptionReversal from 'views/MemberProfile/Header/Buttons/ModaliCashRedemptionReversal'
import ModaliCashTopUp from 'views/MemberProfile/Header/Buttons/ModaliCashTopUp'
import ModaliCashTopUpReversal from 'views/MemberProfile/Header/Buttons/ModaliCashTopUpReversal'
import ModalLoyaltyAllocation from 'views/MemberProfile/Header/Buttons/ModalLoyaltyAllocation'
import ModalLoyaltyAllocationReversal from 'views/MemberProfile/Header/Buttons/ModalLoyaltyAllocationReversal'
import ModalLoyaltyRedemption from 'views/MemberProfile/Header/Buttons/ModalLoyaltyRedemption'
import ModalLoyaltyRedemptionReversal from 'views/MemberProfile/Header/Buttons/ModalLoyaltyRedemptionReversal'
import ModalPurchase from 'views/MemberProfile/Header/Buttons/ModalPurchase'
import ModalPurchaseReversal from 'views/MemberProfile/Header/Buttons/ModalPurchaseReversal'
import { accessControlSubNavigation } from 'setups/controller'

const projectClient = process.env.REACT_APP_PROJ

export const MemberModalModulesData = [
  {
    module: 'Purchase',
    label: 'Purchase',
    value: '1',
    route: {
      title: 'purchase',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModalPurchase,
  },
  {
    module: 'Purchase Reversal',
    label: 'Purchase Reversal',
    value: '1r',
    route: {
      title: 'purchase reversal',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModalPurchaseReversal,
  },
  {
    module: 'Loyalty Redemption',
    label: 'Loyalty Redemption',
    value: '3',
    route: {
      title: 'loyalty redemption',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModalLoyaltyRedemption,
  },
  {
    module: 'Loyalty Redemption Reversal',
    label: 'Loyalty Redemption Reversal',
    value: '3r',
    route: {
      title: 'loyalty redemption reversal',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModalLoyaltyRedemptionReversal,
  },
  {
    module: 'Loyalty Allocation',
    label: 'Loyalty Allocation',
    value: '4',
    route: {
      title: 'loyalty allocation',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModalLoyaltyAllocation,
  },
  {
    module: 'Loyalty Allocation Reversal',
    label: 'Loyalty Allocation Reversal',
    value: '4r',
    route: {
      title: 'loyalty allocation reversal',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModalLoyaltyAllocationReversal,
  },
  {
    module: 'iCash Topup',
    label: 'iCash Topup',
    value: '5',
    route: {
      title: 'icash topup',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModaliCashTopUp,
  },
  {
    module: 'iCash Topup Reversal',
    label: 'iCash Topup Reversal',
    value: '5r',
    route: {
      title: 'icash topup',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModaliCashTopUpReversal,
  },
  {
    module: 'iCash Redemption',
    label: 'iCash Redemption',
    value: '6',
    route: {
      title: 'icash redemption',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModaliCashRedemption,
  },
  {
    module: 'iCash Redemption Reversal',
    label: 'iCash Redemption Reversal',
    value: '6r',
    route: {
      title: 'icash redemption reversal',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModaliCashRedemptionReversal,
  },
  {
    module: 'iCash Donation',
    label: 'iCash Donation',
    value: '7',
    route: {
      title: 'icash donation',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModaliCashDonation,
  },
  {
    module: 'iCash Donation Refund',
    label: 'iCash Donation Refund',
    value: '7r',
    route: {
      title: 'icash donation refund',
      path: {
        path: '',
        permissionIds: [],
      },
    },
    Modal: ModaliCashDonationRefund,
  },
]

export const MEMBERMODALNAVIGATION = accessControlSubNavigation(
  projectClient,
  MemberModalModulesData,
  'member'
)
