import { useCallback, useState } from 'react'

import Button from 'components/Button'
import Spinner from 'components/Spinner'
import { useEmailPreview } from './use-email-preview'
import { CommsDataTypes } from 'types/comms'

export const EmailResend = ({
  commsData,
}: {
  commsData: CommsDataTypes
}): JSX.Element => {
  const { data: dataEmailPreview, isLoading: isLodingEmailPreview } =
    useEmailPreview({ actionId: commsData.id })

  const htmlTemplate = dataEmailPreview ? dataEmailPreview.htmlPreviewUrl : ''
  const textTemplate = dataEmailPreview ? dataEmailPreview.textPreviewUrl : ''
  const attrTemplates = dataEmailPreview
    ? Object.keys(dataEmailPreview.actionAttributes).map((item) => ({
        name: item,
        value: dataEmailPreview.actionAttributes[item],
      }))
    : []

  return (
    <>
      {isLodingEmailPreview ? (
        <div className="flex justify-center items-center h-52">
          <Spinner />
        </div>
      ) : (
        <div className="">
          <div className="mb-8">
            <Button
              iconRight={false}
              type="submit"
              className="rounded bg-primary tracking-wider font-bold"
            >
              Resend
            </Button>
          </div>
          <div className="flex gap-8">
            <div className="w-96 bg-gray-100 bg-opacity-40 rounded-2xl p-4 mb-10 min-h-96">
              <div className="">
                <p className="text-lg tracking-wide font-bold mb-4">
                  Template Variables
                </p>
                <ul className=" flex flex-col">
                  {attrTemplates.map((item) => (
                    <li
                      key={item.name}
                      className="hover:bg-white py-2 px-2 cursor-pointer rounded-xl overflow-hidden"
                    >
                      <p className="font-bold text-black text-opacity-40  tracking-wide">
                        [{item.name}]
                      </p>
                      <p className="font font-bold tracking-wide">
                        {!item.value || item.value === '' ? 'None' : item.value}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
