const Index = ({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}): JSX.Element => (
  <div className="w-full p-6 bg-white ">
    <h3 className="mb-4 tracking-wider font-bold text-3xl">{title}</h3>
    {children}
  </div>
)

export default Index
