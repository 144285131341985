const SearchTips = (): JSX.Element => (
  <div className="box-border absolute flex w-3/4 p-4 my-1 text-base bg-white border border-gray-500 top-12 z-50">
    <div>
      <p>
        <span className="tracking-wider font-bold">Search Tips</span>
      </p>

      <ul className="flex flex-col gap-2 list-disc pl-4 py-4">
        <li className="tracking-wider font-light">
          Use <span className="font-bold">fname:</span> to search by first name
        </li>
        <li className="tracking-wider font-light">
          Use <span className="font-bold">lname:</span> to search by last name
        </li>
        <li className="tracking-wider font-light">
          Use <span className="font-bold">id:</span> to search by trader ID
        </li>
        <li className="tracking-wider font-light">
          Use <span className="font-bold">card:</span> to search by card number
        </li>
        <li className="tracking-wider font-light">
          Use <span className="font-bold">email:</span> to search by
          registration email
        </li>
        <li className="tracking-wider font-light">
          Use <span className="font-bold">phone:</span> to search by phone
        </li>
      </ul>
      <p>
        <span className="tracking-wider font-light">
          * Use % to search with partial data, for example searching for
          &apos;John%&apos; will return John, Johnny, Johnson etc.
        </span>
      </p>
    </div>
  </div>
)

export default SearchTips
